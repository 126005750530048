import React, { useContext, useEffect, useRef, useState } from "react"
import SwitchRowItem from '../tableSwitch'
import SwitchRow from '../switchRow'
import MagnifyingGlassIcon from '../icons/magnifyingGlass'
import { getTokenIcon } from '../../../helpers/token'
import useDebounce from '../../landing/hooks/useDebounce'
import CloseIcon from '../icons/close'
import SearchTable from './searchTable'
import axios from '../../../rest/request'
import TokenContext from '../../../layouts/TokenContext'
import ProSwapContext from '../context/proSwap'

const tabs = [
	{ label: "Top Volume" },
	{ label: "Gainers" },
	{ label: "Losers" },
]

const Search = () => {
	const [active, setActive] = useState(0)
	const [loading, setLoading] = useState(false)
	const [searchValue, setSearchValue] = useState("")
	const [openSearch, setOpenSearch] = useState(false)
	const [searchRes, setSearchRes] = useState([])
	const [gainers, setGainers] = useState([])
	const [losers, setLosers] = useState([])
	const [allPairs, setAllPairs] = useState([])
	const [debounceSearch] = useDebounce(searchValue, 300)
	const { assetsInfo } = useContext(TokenContext)
	const [inputMouseEnter, setInputMouseEnter] = useState(false)
	const searchMenu = useRef(null)

	const handleClick = (e) => {
		if (!searchMenu?.current?.contains(e?.target)) {
			setOpenSearch(false)
		}
	}

	const fetchAnalytics = async () => {
		setLoading(true)
		try {
			const pairs = await axios.post("https://tfm.sspqf.org/graphql", {
				query: `
					query MyQuery {
						period(limit: 5, verifiedOnly: false) {
							  content {
								  volume24
								  liquidity
								  absoluteVolume24
								  diff24
								  contractAddr
								  token0Address
								  token1Address
								  priceInverted
								  price
								  market
							  }
						}
					}
           		`,
			})

			const gainers = await axios.post("https://tfm.sspqf.org/graphql", {
				query: `
					query MyQuery {
				period(field: "diff_24", limit: 5, volumesLimit: true) {
							content {
						priceInverted
						price
						contractAddr
						diff24
						token0Address
						token1Address
						liquidity
						volume24
						absoluteVolume24
						market
					}
				}
			}
				`,
			})

			const losers = await axios.post("https://tfm.sspqf.org/graphql", {
				query: `
					query MyQuery {
				period(field: "diff_24", limit: 5, asc: true, volumesLimit: true) {
							content {
						volume24
						price
						priceInverted
						diff24
						contractAddr
						token0Address
						token1Address
						liquidity
						absoluteVolume24
						market
					}
				}
			}
				`,
			})

			if (pairs.status === 200) {
				let tmpPairs = pairs.data.data.period.content
					.map((a) => {
						return {
							...a,
							name: assetsInfo[a.token0Address]?.symbol + "/" + assetsInfo[a.token1Address]?.symbol,
							firstIcon: getTokenIcon(a.token0Address),
							secondIcon: getTokenIcon(a.token1Address)
						}
					})

				setAllPairs(tmpPairs)
				setSearchRes(tmpPairs)
			}

			if (losers) {
				let tmpLosers = losers.data.data.period.content
					.map((item) => {
						return {
							...item,
							name: assetsInfo[item.token0Address]?.symbol + "/" + assetsInfo[item.token1Address]?.symbol,
							firstIcon: getTokenIcon(item.token0Address),
							secondIcon: getTokenIcon(item.token1Address),
						}
					})

				setLosers(tmpLosers)
			}

			if (gainers) {
				let tmpGainers = gainers.data.data.period.content
					.map((item) => {
						return {
							...item,
							name: assetsInfo[item.token0Address]?.symbol + "/" + assetsInfo[item.token1Address]?.symbol,
							firstIcon: getTokenIcon(item.token0Address),
							secondIcon: getTokenIcon(item.token1Address),
						}
					})

				setGainers(tmpGainers)
			}
		} catch (e) {
			console.warn(e.message)
		}
		setLoading(false)
	}

	const searchPairs = async () => {
		setLoading(true)
		try {
			const response = await axios.post("https://tfm.sspqf.org/graphql", {
				query: `
					query MyQuery {
				searchPeriod(data: "${searchValue}", limit: 5, searchFullText: false) {
					volume24
					liquidity
					absoluteVolume24
					diff24
					contractAddr
					token0Address
					token1Address
					priceInverted
					price
					market
				}
			}
				`,
			})

			if (response.status === 200) {
				let tmpPairs = response.data.data.searchPeriod
					.map((a) => {
						return {
							...a,
							name: assetsInfo[a.token0Address]?.symbol + "/" + assetsInfo[a.token1Address]?.symbol,
							firstIcon: getTokenIcon(a.token0Address),
							secondIcon: getTokenIcon(a.token1Address)
						}
					})

				setSearchRes(tmpPairs)
			}
		} catch (e) {
			console.warn(e.message)
		}
		setLoading(false)
	}

	// get top volume, gainers, losers
	useEffect(() => {
		if (Object.keys(assetsInfo).length)
			fetchAnalytics()
	}, [assetsInfo])

	// search tokens by symbol, market, address
	useEffect(() => {
		if (debounceSearch !== '') {
			setActive(0)
			searchPairs()
		} else {
			setSearchRes(allPairs)
		}
	}, [debounceSearch])

	// close search by clicking on a third-party element
	useEffect(() => {
		if (openSearch) {
			document.addEventListener("click", handleClick)
			return () => {
				document.removeEventListener("click", handleClick)
			}
		}
	}, [openSearch])

	return (
		<div ref={searchMenu} className="relative w-full max-w-full">
			<div
				onClick={() => {
					setOpenSearch(true)
				}}
				onMouseEnter = {() => {
					setInputMouseEnter(true)
				}}
				onMouseLeave ={() => {
					setInputMouseEnter(false)
				}}
				className={`flex items-center pl-3 pr-4 lg: pl-4 py-2 searchBorder rounded-base cursor-pointer hover:border-search h-10 relative w-full 
				${openSearch ? "searchInputActive noBottomBorder" : 'justify-center'} `}
			>
				<div className="hidden xl:flex items-center">
					<MagnifyingGlassIcon strokeColor={inputMouseEnter ? '#FFFFFF' : '#45434F'}/>
				</div>

				<div className={`xl:hidden flex items-center justify-center ${openSearch && "right-1px relative"} `}>
					<MagnifyingGlassIcon width="20" height="20" strokeColor="#FFFFFF" />
				</div>

				<input
					style={{ textOverflow: 'ellipsis' }}
					className={`ml-2 w-full text-base placeholder-black-60 font-poppins`}
					id="search"
					name="search"
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
					autoComplete="off"
					placeholder="Search market, use ticker or token address"
				/>

				<div
					className={`${openSearch ? "flex items-center  xl:hidden" : 'hidden'} `}
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						setOpenSearch(false)
						setSearchValue("")
					}}
				>
					<CloseIcon width="20" height="20" />
				</div>


			</div>
			{openSearch && (
				<div className="absolute top-39 left-0 w-full z-10">
					<div
						className={`w-full favorites rounded-lg pt-4 text-white font-poppins ${openSearch ? "noTopBorder searchOpenBorder" : ""}`}
					>
						<div className="w-full sm:w-max px-4">
							<SwitchRow>
								{tabs.map((item, index) =>
									<SwitchRowItem
										className="text-sm"
										key={item.label}
										active={index === active}
										onClick={() => setActive(index)}
									>
										{item.label}
									</SwitchRowItem>
								)}
							</SwitchRow>
						</div>

						{active === 0 && (
							<SearchTable
								data={searchRes}
								loading={loading}
								setSearchValue={setSearchValue}
								setOpenSearch={setOpenSearch}
								assetsInfo={assetsInfo}
							/>
						)}

						{active === 1 && (
							<SearchTable
								data={gainers}
								loading={loading}
								setSearchValue={setSearchValue}
								setOpenSearch={setOpenSearch}
								assetsInfo={assetsInfo}
							/>
						)}

						{active === 2 && (
							<SearchTable
								data={losers}
								loading={loading}
								setSearchValue={setSearchValue}
								setOpenSearch={setOpenSearch}
								assetsInfo={assetsInfo}
							/>
						)}
					</div>
				</div>
			)}
		</div>

	)
}

export default Search
