import React, { ReactNode, useEffect, useState, useLayoutEffect, useCallback } from 'react';
import { useAddress } from "hooks"

const FavoriteContext = React.createContext({
  tokens: [],
  pairs: [],
  update: () => { },
  addToken: (address: string) => { },
  addPair: (address: string) => { },
  removeToken: (address: string) => { },
  removePair: (address: string) => { },
});

interface Props {
  children: ReactNode;
}

const tokensKey = (address: string) => `${address}:fav:tokens`
const pairsKey = (address: string) => `${address}:fav:pairs`

const getFavorite = (address: string, keyFunction: (address: string) => string) => {
  const stored = localStorage.getItem(keyFunction(address))

  try {
    const array = JSON.parse(stored)

    if (Array.isArray(array))
      return array

    return []
  }
  catch {
    return []
  }
}

const addFavorite = (address, setFunction) => {
  if (!address)
    return

  setFunction(prev => [...prev, address])
}

const removeFavorite = (address, setFunction) => {
  if (!address)
    return

  setFunction(prev => prev.filter(item => item !== address))
}

const FavoriteContextProvider = ({ children }: Props) => {

  const [tokens, setTokens] = useState<string[]>([])
  const [pairs, setPairs] = useState<string[]>([])

  const wallet = useAddress()

  const getTokens = useCallback((address) => getFavorite(address, tokensKey), [])
  const getPairs = useCallback((address) => getFavorite(address, pairsKey), [])

  const update = useCallback(() => {
    if (!wallet)
      return

    setTokens(getTokens(wallet))
    setPairs(getPairs(wallet))
  }, [wallet])

  const addToken = useCallback((address) => {
    addFavorite(address, setTokens)
  }, [])

  const addPair = useCallback((address) => {
    addFavorite(address, setPairs)
  }, [])

  const removeToken = useCallback((address) => {
    removeFavorite(address, setTokens)
  }, [])

  const removePair = useCallback((address) => {
    removeFavorite(address, setPairs)
  }, [])

  useLayoutEffect(() => {
    if (wallet)
      update()
  }, [wallet])

  useEffect(() => {
    if (!wallet)
      return

    localStorage.setItem(tokensKey(wallet), JSON.stringify(tokens))
  }, [tokens])

  useEffect(() => {
    if (!wallet)
      return

    localStorage.setItem(pairsKey(wallet), JSON.stringify(pairs))
  }, [pairs])

  return (
    <FavoriteContext.Provider
      value={{
        tokens,
        pairs,
        update,
        addToken,
        addPair,
        removeToken,
        removePair,
      }}
    >
      {children}
    </FavoriteContext.Provider>
  );
};

export default FavoriteContext;

export { FavoriteContextProvider as FavoriteContextProvider };