import React from 'react'

const PlayIcon = () => {
    return (
        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" className=''>
            <path d="M0.5 5.66667V16.3333C0.5 19.3105 0.5 20.799 1.47101 21.3817C2.44202 21.9643 3.75546 21.2638 6.38235 19.8627L16.3824 14.5294C19.2941 12.9765 20.75 12.2 20.75 11C20.75 9.8 19.2941 9.02353 16.3824 7.47059L6.38235 2.13725C3.75546 0.736246 2.44202 0.0357424 1.47101 0.618348C0.5 1.20095 0.5 2.68952 0.5 5.66667Z" fill="white" />
        </svg>

    )
}

export default PlayIcon