import React from "react";
import SearchCollectionItem from "./SearchCollectionItem";
import SearchTokenItem from "./SeearchTokenItem";

const SearchBarDropDownList = ({ collections, tokens, close = ()=>{} }) => {
    return (
        <div className="py-4 pt-6">
            {
                collections.length > 0 &&
                    <div className="mb-2">
                        <div className="text-sm opacity-70 mb-2 ml-4">
                            Collections
                        </div>
                        {collections.map(item => <SearchCollectionItem item={item}  close = {close}/>)}

                    </div>
            }
            {
                tokens.length > 0 &&
                <div>
                    <div className="text-sm opacity-70 mb-2 ml-4">
                        Items
                    </div>
                    {tokens.map(item => <SearchTokenItem item={item} close = {close} />)}
                </div>
            }

        </div>
    )
}
export default SearchBarDropDownList