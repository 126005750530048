import React, { ReactNode, useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import ResizeContext from './resize';

export enum Type {
  "SWAP" = "Swap",
  "LIQUIDITY" = "Liquidity",
  "MULTISWAP" = "Multiswap",
  "PROTRADE" = "Pro Trade",
  // "LIMIT" = "Limit",
  "PROVIDE" = "Add LP",
  "WITHDRAW" = "Withdraw LP",
}

const SwapContext = React.createContext({
  type: Type.SWAP,
  setType: (arg: Type) => { },
  chartOpen: false,
  setChartOpen: (value: boolean) => { },
  tradeHistoryOpen: false,
  setTradeHistoryOpen: (value: boolean) => { },
  toggleChart: () => { },
  toggleHistory: () => { },
});

interface Props {
  children: ReactNode;
}

const SwapContextProvider = ({ children }: Props) => {
  const [type, setType] = useState(Type.SWAP)

  const { subscribeOnResize } = useContext(ResizeContext)

  const [chartOpen, setChartOpen] = useState(false)
  const [tradeHistoryOpen, setTradeHistoryOpen] = useState(false)

  const toggleChart = useCallback(() => setChartOpen((prev) => !prev), [])
  const toggleHistory = useCallback(() => setTradeHistoryOpen((prev) => !prev), [])

  useLayoutEffect(() => {
    subscribeOnResize(() => {
      setChartOpen(false)
      setTradeHistoryOpen(false)
    })
  }, [])

  return (
    <SwapContext.Provider
      value={{
        type,
        setType,
        chartOpen,
        setChartOpen,
        tradeHistoryOpen,
        setTradeHistoryOpen,
        toggleChart,
        toggleHistory,
      }}
    >
      {children}
    </SwapContext.Provider>
  );
};

export default SwapContext;

export { SwapContextProvider };