import { Class } from "@material-ui/icons"

export class gqlQueryBuilder<Parameters, Response> {
  private optionalParams: Partial<Parameters>
  private requestFields: Partial<Response>

  private requeredParams: string[]

  private name: string

  private cacheFields: string = ""
  private cacheParams: string = ""

  constructor(name: string, requeredParams: string[] = []) {
    this.optionalParams = {}
    this.requestFields = {}
    this.name = name
    this.requeredParams = requeredParams
  }

  private buildFields() {
    this.cacheFields = Object.keys(this.requestFields).join("\n")
  }

  private buildParams() {
    this.cacheParams = Object.keys(this.optionalParams)
      .map((name) => {
        const value = this.optionalParams[name as keyof Parameters]
        return `${name}:${typeof value === "number" ? value : `"${value}"`}`
      })
      .join(", ")
  }

  setParameter(name: keyof Parameters, value: any) {
    this.optionalParams[name] = value

    this.buildParams()

    return this
  }

  removeParameter(name: keyof Parameters, value: any) {
    if (!(name in this.optionalParams)) return this

    delete this.optionalParams[name]

    this.buildParams()

    return this
  }

  addField(name: keyof Response) {
    if (name in this.optionalParams) throw new Error(`${name} parameter is already has been added`)

    this.requestFields[name] = undefined

    this.buildFields()

    return this
  }

  removeField(name: keyof Response) {
    if (!(name in this.requestFields)) return this

    delete this.requestFields[name]

    this.buildFields()

    return this
  }

  clear() {
    this.optionalParams = {}
    this.requestFields = {}
    this.cacheFields = ""
    this.cacheParams = ""

    return this
  }

  create(): string {
    if (!Object.keys(this.requestFields).length) throw new Error("Query must have atleast one field")

    this.requeredParams.forEach((name) => {
      if (!this.optionalParams[name as keyof Parameters]) throw new Error(`parameter ${name} is requered`)
    })

    return `${this.name}${this.cacheParams ? `(${this.cacheParams})` : ""}{\n${this.cacheFields}\n}`
  }

  isEmpty(): boolean {
    return !Object.keys(this.requestFields).length
  }
}

export class gqlBuilder<Parameters, Response> {
  private dictionary: Record<string, gqlQueryBuilder<any, any>> = {}
  private activeBuilder: string = ''
  private name: string
  private optionalParams: Partial<Parameters> = {}
  private cacheParams: string = ""


  constructor(
    name: string
  ) {
    this.name = name
  }

  addNestedObject(name: string) {
    this.dictionary[name] = new gqlQueryBuilder(name)

    return this
  }

  setBuilder(name:string){
    const builder = this.dictionary[name] 
    if (!builder)
    throw new Error(`Dictionary doesn't contain ${name}`)

    this.activeBuilder = name
    return this
  }

  addField(name:string){
    if (!this.activeBuilder)
    throw new Error(`Active builder is not selected`)

    this.dictionary[this.activeBuilder].addField(name)

    return this
  }

  private buildParams() {
    this.cacheParams = Object.keys(this.optionalParams)
      .map((name) => {
        const value = this.optionalParams[name as keyof Parameters]
        return `${name}:${typeof value === "number" ? value : `"${value}"`}`
      })
      .join(", ")
  }

  setParameter(name: keyof Parameters, value: any) {
    this.optionalParams[name] = value

    this.buildParams()

    return this
  }

  removeParameter(name: keyof Parameters, value: any) {
    if (!(name in this.optionalParams)) return this

    delete this.optionalParams[name]

    this.buildParams()

    return this
  }

  isEmpty(): boolean {
    return Object.keys(this.dictionary)?.every(item => this.dictionary[item].isEmpty())
  }

  create(){
    const fields = Object.keys(this.dictionary).map(item => this.dictionary[item].create() ).join('\n')
    return `query query {${this.name}${this.cacheParams ? `(${this.cacheParams})` : ""}{\n${fields}\n}}`
  }
}