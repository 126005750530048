type TMarketplaceName = "luart" | "random_earth" | "randomearth" |"knowhere_art"| "messier"| "talis" | "oneplanet"
interface IMarketplaceInfo{
    image: string,
    name: string,
    url: string,
    getTokenRoute: (collection:string, token:string) => string
}

export type TMarketplaceDictionary<T> = Record<TMarketplaceName, T>

const dictionary : TMarketplaceDictionary<IMarketplaceInfo> = {
    "luart":{
        "image": "/images/markets/LuartLogo.svg",
        "name" : "Luart",
        "url": "https://luart.io/",
        "getTokenRoute": (c, t) => {return `https://marketplace.luart.io/collections/${c}/${t}`}
    },
    "random_earth":{
        "image": "/images/markets/randomEarthLogo.svg",
        "name" : "RandomEarth",
        "url": "https://randomearth.io/",
        "getTokenRoute": (c, t) => {return `https://v2.randomearth.io/items/${c}_${t}`}
    },
    "randomearth":{
        "image": "/images/markets/randomEarthLogo.svg",
        "name" : "RandomEarth",
        "url": "https://randomearth.io/",
        "getTokenRoute": (c, t) => {return `https://v2.randomearth.io/items/${c}_${t}`}

    },
    "knowhere_art":{
        "image": "/images/markets/knowhere.svg",
        "name" : "Knowhere Art",
        "url": "https://knowhere.art/",
        "getTokenRoute": (c, t) => {return `https://knowhere.art/nft/${c}/${t}`}
    },
    "messier":{
        "image": "/images/markets/messier.svg",
        "name" : "Messier Art",
        "url": "https://www.messier.art/",
        "getTokenRoute": (c, t) => {return `https://www.messier.art/bid/${c}/${t}`}

    },
    "talis":{
        "image": "/images/markets/talis.svg",
        "name" : "Talis",
        "url": "https://talis.art/",
        "getTokenRoute": (c, t) => {return `https://talis.art/`}

    },
    "oneplanet":{
        "image": "/images/markets/oneplanet.svg",
        "name" : "OnePlanet",
        "url": "https://www.oneplanetnft.io/",
        "getTokenRoute": (c, t) => {return `https://www.oneplanetnft.io/nfts/${c}_${t}`}

    },

}
export default dictionary
