import React, { Dispatch, SetStateAction, useLayoutEffect, useState } from 'react'
import ReactDOM from 'react-dom'

const BottomDrawer = ({ children, open = false, setOpen = () => { }, hideAt = 1280 }: { children: JSX.Element, open: boolean, setOpen?: Dispatch<SetStateAction<boolean>>, hideAt?: number }) => {
  const [container, setContainer] = useState(undefined)

  useLayoutEffect(() => {
    setContainer(document.getElementById("bottomDrawer"))
  }, [])

  const handleResize = (e) => {
    const html = document.querySelector("html")

    if (html.clientWidth >= hideAt) {
      setOpen(false)
    }
  }

  useLayoutEffect(() => {
    if (document.querySelector("html")?.clientWidth >= hideAt) {
      setOpen(false)
    }
    else {
      if (open) {
        document.querySelector("html").classList.add("overflow-hidden")
        window.addEventListener("resize", handleResize)
      }
      else {
        document.querySelector("html").classList.remove("overflow-hidden")
        window.removeEventListener("resize", handleResize)
      }
    }

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [open])

  const handelClickOutside = (e) => {
    if (e.target.matches("[data-drawer]"))
      setOpen(false)
  }

  if (!container)
    return <></>

  return ReactDOM.createPortal(
    <div className={`w-full h-full absolute top-0 ${open ? 'bg-opacity-80 bg-fade' : ''}`}>
      <div onClick={handelClickOutside} className={`bg-opacity-100 absolute w-full h-full child-pointer-events-all drawer ${open ? 'open' : ''}`}>
        {React.cloneElement(children, { "data-drawer": true }, children.props.children)}
      </div>
    </div>,
    container
  );
}

export default BottomDrawer