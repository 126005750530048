import React from "react";

const ErrorWarning = () => {
    return (

        <svg width="80" height="80" viewBox="0 0 80 80" className="wFull hFull" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40 30V45" stroke="#FC5B5B" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M35.6725 12.4952L8.17996 59.9928C7.74004 60.7528 7.50805 61.6153 7.50733 62.4935C7.5066 63.3716 7.73716 64.2345 8.17582 64.9952C8.61448 65.756 9.24576 66.3878 10.0061 66.827C10.7665 67.2663 11.6292 67.4976 12.5073 67.4976H67.4925C68.3707 67.4976 69.2333 67.2663 69.9937 66.827C70.7541 66.3878 71.3854 65.756 71.824 64.9952C72.2627 64.2345 72.4932 63.3716 72.4925 62.4935C72.4918 61.6153 72.2598 60.7528 71.8199 59.9928L44.3273 12.4952C43.8881 11.7364 43.2571 11.1064 42.4975 10.6685C41.738 10.2305 40.8767 10 39.9999 10C39.1232 10 38.2618 10.2305 37.5023 10.6685C36.7428 11.1064 36.1118 11.7364 35.6725 12.4952V12.4952Z" stroke="#FC5B5B" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40 60C42.0711 60 43.75 58.3211 43.75 56.25C43.75 54.1789 42.0711 52.5 40 52.5C37.9289 52.5 36.25 54.1789 36.25 56.25C36.25 58.3211 37.9289 60 40 60Z" fill="#FC5B5B" />
        </svg>
    )
}

export default ErrorWarning