import { IDictionary } from ".."

const en = {
  header: {
    menu1: "TFM",
    menu2: "Ranks",
    menu3: "Roadmap",
    menu4: "Token",
    menu5: "Team",
    button: "Launch App",
  },
  indexPage: {
    header: "TFM",
    subHeader: "The World Engine for the Terra Ecosystem",
    subSubHeader: "TFM is a liquidity stack for startups during all stages of growth, across asset classes.",
    terraformingHeader: "Terraformation Begins Here",
    terraformingSubHeader:
      "TFM provides the technology, community-led funding, support network, and marketing that Terra startups need to build on terra for the next billion blockchain users",
    terra1Head: "Bootstrap Token Economies",
    terra1Content: "Capitalize your launch directly from product users and Terra’s most active community.",
    terra2Head: "Boost Liquidity",
    terra2Content: "Instant liquidity farms that incentivize the community to bring depth to early stage tokens.",
    terra3Head: "Reduce Circulation",
    terra3Content: "Deploy strategic staking campaigns to actively control circulation.",
    terra4Head: "Bootstrap NFT Communities",
    terra4Content: "Conduct the primary launch of a NFT collection through a host of sale formats.",
    terra5Head: "NFT Liquidity",
    terra5Content: "Enjoy a secondary market for NFTs with advanced search features.",
    terra6Head: "Liquidity as a Service",
    terra6Content:
      "Capitalize on opportunities across the Terra economy, through either leasing or borrowing liquidity.",
    featuresHeader: "Features",
    feat1Head: "Bootstrap Token Economies",
    feat1Content:
      "Tap into a wide variety of token launch options. Host an IDO or permissionless no-loss sales, auctions, LBPs, and more. </br></br>Each sale format has unique advantages. IDOs maximize marketing, while other formats can advance price discovery or liquidity depth.",
    feat2Head: "AMM Focused on Early Assets",
    feat2Content:
      "AMM Focused on Early Assets. TFM offers an automated market maker (AMM) with a primary focus on low cap, early assets. </br></br>It has a direct tap to the platform’s token offering solution, offers early stage tokens an AMM where they stand out, and an opportunity for market participants to easily acquire tokens of new startups.",
    feat4Head: "NFT Markets",
    feat4Content:
      "Host NFT sales through a wide variety of formats, each with its own value point ranging from increased liquidity to maximized distribution. </br></br>The primary market will be a curated platform, with a particular focus on GameFi and metaverse assets. Assets will be available on TFM’s NFT marketplace at the closure of NFT primary sales.",
    feat3Head: "Liquidity Acceleration",
    feat3Content:
      "Deploy white label solutions that improve token liquidity. </br></br>TFM’s white label LP farms incentivize the community to boost asset depth. Meanwhile, the white label single asset staking help teams, DAOs, and market makers reduce unlocked circulation, a key tool in liquidity strategies.",
    ranksHeader: "Terraform Ranks",
    rank1: "TFM",
    rank1Text:
      "Demons crept into the world trying to infiltrate the races to find a way to steal the magic crystals in order to bring back the demon lord to take over the world again. If demons want to increase their strength, they have to kill more and more so that their blood-drinking dark magic will become stronger. The Demon’s power will increase many times especially after killing magical and small, beautiful, weak species like the Fairy.",
    rank2: "General",
    rank2Text: "Rank Stake:\nPending",
    rank3: "Colonel",
    rank4: "Captain",
    rank5: "Lieutenant",
    roadmapHeader: "Roadmap",
    q1: "Market Assessment Advisor Consultation",
    q2: "Team Formation\nProject Formalization\nRoadmap Established\nUI & UX Creation\nSeed Sale",
    q3: "Launchpad Deployment\nLiquidity Stack Deployment\nAMM Deployment",
    q4: "Private\nTFM Distribution\nTFM Liquidity\nNFT Primary Offerings",
    q5: "Auction Mechanism\nLBP Mechanism\nPermissionless Offerings\nNFT Market Beta",
    tokenHeader: "Token Economy",
    tokenInfo:
      "TFM is the membership asset of the TFM ecosystem. Holders get increased access and discounts across TFM products.",
    tokenSubheader1: "Launchpad Access",
    tokenSubhInfo1:
      "TFM stakers are qualified across different tiers, each getting a portion of the allocated public sale supply of a token sale.",
    tokenSubheader2: "Trading Advantages",
    tokenSubhInfo2: "TFM stakers earn discounts on trades done on the TFM automated market maker and NFT marketplace.",
    teamHeader: "Team",
    teamMember1Name: "Pedro Pereira",
    teamMember1Role: "CEO",
    teamMember2Name: "Kevin Sayer",
    teamMember2Role: "Community Lead",
    teamMember3Name: "Mario Johanson",
    teamMember3Role: "CTO",
    teamMember4Name: "Daniel Alves",
    teamMember4Role: "Head of Growth",
    teamMember5Name: "Steve Lee",
    teamMember5Role: "Head of NFT",
    advisorsHeader: "Advisors",
    advisorMember1Name: "Hatu Sheikh",
    advisorMember1Role: "CEO",
    button: "Launch App",
    more: "More info",
    planetInfo: "Terraforming",
    terraforming: "Terraforming",
    waitlist: "Join whitelist",
    send: "Send",
    email: "Email",
    name: "Name",
    emailPlaceholder: "example@mail.com",
    namePlaceholder: "John Doe",
    hintName: "Enter any name",
    hintEmail: "Enter a valid email address",
    messageSuccess: "Your information is successfully submited!",
    messageFail: "Your are already on the list!",
  },
  footer: {
    main: "TFM",
    company: "Company",
    resources: "Resources",
    links: "Links",
    company1: "About",
    company2: "Roadmap",
    company3: "Token",
    company4: "Team",
    resources1: "Brand Assets",
    resources2: "Whitepaper",
    links1: "Privacy Policy",
    links2: "Disclaimer",
    links3: "Cookies Policy",
    links4: "Terms and Conditions",
    links5: "Brand Assets",
    email1: "Sign up for Newsletter",
    email2: "example@mail.com",
    messageSuccess: "Thank you!",
    messageFail: "Your email is already on the list",
    messageFail2: "Incorrect Email",
  },
}

export default en
