import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";


const NFTFilterContext = React.createContext({
  openFilter: false,
  setOpenFilter: (arg) => { },
  visible: true,
  setVisible: (arg) => { },
  searchParams: {} as ISearchParams,
  setSearchParams: (arg) => { },
  setSearchParam: (name, value) => { },
  removeSearchPath: () => { },
  setSearchParamByArr: (value) => { },
  filterProps: '',
  setFilterProps: (arg: string) => { },
  removeSearchParams: (arr: TSearchKeys[]) => { },
  setDecimals: (arg: number) => { }

});

interface ISearchParams {
  tokenId?: string
  rankStart?: number
  rankFinish?: number
  sorting?: string
  ASC?: boolean
  traits?: string
  priceStart?: number
  priceFinish?: number
  status?: string
  market?: string[]
  sellNow?: boolean
  denom?: string
}

type TSearchKeys = keyof ISearchParams

const returnString = (value) => `"${value}"`
const returnNonString = (value) => value
const returnPrice = (value, decimals) => (value * 10 ** decimals)


export const reqExample = {
  tokenId: returnString,
  rankStart: returnNonString,
  rankFinish: returnNonString,
  sorting: returnString,
  ASC: returnNonString,
  traits: returnString,
  priceStart: returnPrice,
  priceFinish: returnPrice,
  status: returnString,
  market: returnString,
  sellNow: returnNonString,
  denom: returnString
}

const NFTFilterContextProvider = ({ children }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [visible, setVisible] = useState(true);
  const [searchParams, setSearchParams] = useState<ISearchParams>({})
  const [filterProps, setFilterProps] = useState('')
  const [decimals, setDecimals] = useState(0)
  const location = useLocation()
  const history = useHistory()


  useEffect(() => {
    const data = Array.from(new URLSearchParams(location.search).entries())
    const temp = {}
    data.forEach(item => temp[item[0]] = item[1].split(','))
    setSearchParams(temp)
  }, [location])

  useEffect(() => {
    let temp = {}
    Object.keys(searchParams).map(item => {
      if (reqExample[item]) {
        temp[item] = searchParams[item]
      } else {
        temp['traits'] = temp['traits'] ? `${temp['traits']};${item}:${searchParams[item]}` : `${item}:${searchParams[item]}`
      }
    })
    let params = Object.keys(temp).map(item => item + ':' + reqExample[item](temp[item], decimals))
    setFilterProps(params.join(','))
  }, [searchParams, decimals])

  const setSearchParam = (name, value) => {
    const a = new URLSearchParams(location.search)
    if (!value.length) {
      if (name === 'priceStart') {
        a.delete('priceFinish')
      }
      if (name === 'rankStart') {
        a.delete('rankFinish')
      }
      a.delete(name)
    }
    else
      a.set(name, value)
    history.replace({ pathname: location.pathname, search: '?' + a.toString() })
  }


  const setSearchParamByArr = (value) => {
    const a = new URLSearchParams(location.search)
    value.map(value => Object.keys(value).map(item => {
      if (!value[item]?.length)
        a.delete(item)
      else
        a.set(item, value[item])
      history.replace({ pathname: location.pathname, search: '?' + a.toString() })
    }))


  }


  const removeSearchParams = (arr: TSearchKeys[]) => {
    const a = new URLSearchParams(location.search)
    arr.map(item => a.delete(item))
    history.replace({ pathname: location.pathname, search: '?' + a.toString() })
  }

  const removeSearchPath = () => {
    const a = new URLSearchParams(location.search)
    let asc = a.get('ASC')
    let sorting = a.get('sorting')
    const param = new URLSearchParams()
    if (asc) {
      param.set('ASC', asc)
    }
    if (sorting) {
      param.set('sorting', sorting)
    }
    history.replace({ pathname: location.pathname, search: '?' + param.toString() })
  }


  return (
    <NFTFilterContext.Provider
      value={{
        openFilter,
        setOpenFilter,
        visible,
        setVisible,
        searchParams,
        setSearchParams,
        setSearchParam,
        removeSearchPath,
        setSearchParamByArr,
        filterProps,
        setFilterProps,
        removeSearchParams,
        setDecimals
      }}
    >
      {children}
    </NFTFilterContext.Provider>
  );
};

export default NFTFilterContext;

export { NFTFilterContextProvider };