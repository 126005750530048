import Button from "./button";
import React, { useCallback } from "react";
import { useAddress, useNetwork } from "hooks";
import { truncate } from "../../libs/text";
import { useWallet } from "@terra-money/wallet-provider";

const WalletButton = ({ className = "", paddingOff = false }: { className?: string, paddingOff?: boolean }) => {
  const { name } = useNetwork()
  const { disconnect } = useWallet()
  const address = useAddress()

  const handleClick = useCallback(() => {
    disconnect()
  }, [])

  const convertName = name[0].toUpperCase() + name.slice(1, name.length)
  return (
    <Button paddingOff onClick={handleClick} {...{ className }}>
      {truncate(address)} | {convertName}
    </Button>
  );
}

export default WalletButton