import React from "react";
import MagnifyingGlassIcon from "components/platform/icons/magnifyingGlass";
import { Link, Switch } from "react-router-dom";
import formatNumbers from "helpers/formatNumbers";
import tokensDictionary from 'constants/tokensDictionary.json';
import ImageChecker from "./imageChecker";


const SearchCollectionItem = ({ item,close }) => {
    return (
        <Link to={`/nft/collections/${item.collectionAddr}/items/?sorting=rarity&ASC=false&sellNow=false`}
        onClick = {close} >
            <div className="py-2 bg- hover:bg-black-70  items-center  flex px-4">
                <div className="flex items-center mr-2">
                    <ImageChecker 
                    url={item.src} 
                    containerClassName = {'rounded-full w-8 h-8 mr-2 text-sm'} 
                    noPlayButton 
                    className="w-full"
                    loaderHeight="h-4"
                    />
                </div>
                <div className="flex flex-col">
                    <div className="text-sm">{item.collectionName}</div>
                    <div className='opacity-70 text-xs  lg:text-xs-d lg:flex items-center  '>
                        <p className="flex items-center">
                            {formatNumbers(item.itemsCount)} Items • {formatNumbers(item.ownersCount) || '-'} Owners  •
                            {
                                item.floorPrice?.denom &&
                                <img src={tokensDictionary[item.floorPrice.denom]?.icon} className='w-3 h-3 lg:w-4 lg:h-4 mx-1' />
                            }
                            {formatNumbers(item.floorPrice.price / 10 ** tokensDictionary[item.floorPrice.denom]?.decimals) || '-'} Floor price •
                            {
                                item.volume?.denom &&
                                <img src={tokensDictionary[item.volume.denom]?.icon} className='w-3 h-3 lg:w-4 lg:h-4 mx-1' />
                            }
                        </p>

                        <p>
                            {formatNumbers(item?.volume?.price / 10 ** tokensDictionary[item?.volume?.denom]?.decimals) || '-'} Volume traded
                        </p>
                    </div>
                </div>

            </div>

        </Link>

    )
}
export default SearchCollectionItem