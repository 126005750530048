import { valueOrNull } from "helpers/formatNumbers"
import { formatTraits } from "helpers/formatTraits"
import { gqlBuilder, gqlQueryBuilder } from "helpers/gql"
import { subServiceToQuery } from "helpers/serviceToGQLQuery"
import { path, prop } from "ramda"
import React, { ReactNode, useEffect, useLayoutEffect, useState } from "react"
import axios from "rest/request"
import collectionsToLook from "../../../constants/nftCollections.json"
import { ActivityFilters } from "../NFT/charts/statsCharts"

const NftContext = React.createContext<{
  collections: Record<string, ICollection>
  loading: boolean
  collectionsLoading: boolean
  tokens: any
  tokenListCount: number
  nextPage: boolean
  hasNextCollectionPage: boolean
  getNextTokens: (
    collectionAddr?: string,
    from?: number,
    to?: number,
    filterProps?: string
  ) => any
  getCollections: (
    limit: number,
    offset: number,
    sorting: string,
    ASC: boolean,
    reset?: boolean
  ) => void
  getTokenInfo: (collectionAddr: string, tokenId: string) => any
  getHistoricFloorPrice: (
    collectionAddr: string,
    limit: number,
    resolutionSec: number,
    from: number,
    to: number
  ) => any
  getActivityListings: (
    collectionAddr: string,
    limit: number,
    filters: ActivityFilters,
    page: number
  ) => any
  getActivityTrades: (
    collectionAddr: string,
    limit: number,
    filters: ActivityFilters,
    page: number
  ) => any
  getActivityAllNFTs: (
    collectionAddr: string,
    limit: number,
    filters: ActivityFilters,
    page: number
  ) => any
  getNFTStats: (
    subServiceToQuery: string,
    collectionAddr: string,
    // limit: number,
    resolutionSec: number,
    timeFrom: number,
    timeTo: number
  ) => any
  getTokenListing: (
    collectionAddress: string,
    tokenID: string,
    market?: string
  ) => any
  getChartStats: (
    collectionAddr: string,
    type: string,
    resolution: number
  ) => any
  setNextPage: (arg: boolean) => void
  queryCollection: (
    address: string,
    fields: TCollectionFields[]
  ) => Promise<void>
  makeSearch: (value: string) => any
  setCollections: (arg: any) => void
  getOnePlanetAdditionalData: (arg: string) => any
}>({
  collections: {},
  nextPage: true,
  loading: true,
  collectionsLoading: true,
  tokens: [],
  tokenListCount: 0,
  getCollections: () => { },
  getNextTokens: () => { },
  getTokenInfo: () => { },
  getHistoricFloorPrice: () => { },
  getActivityListings: () => { },
  getActivityTrades: () => { },
  getActivityAllNFTs: () => { },
  getNFTStats: () => { },
  getTokenListing: () => { },
  queryCollection: () => new Promise(() => { }),
  setNextPage: () => { },
  makeSearch: () => { },
  setCollections: () => { },
  hasNextCollectionPage: true,
  getOnePlanetAdditionalData: () => { },
  getChartStats: () => { }
})

interface ITrait {
  type: string
  value: string
}

interface ICollectionInfo {
  name: string
  symbol: string
}

interface INFT {
  id: string
  name: string
  imageURL: string
  collection: ICollectionInfo
  traits: ITrait[]
}

interface IPageInfo {
  count?: number
  hasNextPage?: boolean
  hasPreviousPage?: boolean
  offset?: number
}

interface ICollection extends ICollectionInfo {
  volume7d: any
  volume30d: any
  tokensTradeCount: any
  volume1d: any
  traitsCount: any
  market: string
  authorLink: any
  authorLogo: any
  isVerifiedAutor: any
  author: string
  collectionAddr: any
  bannerSrc: string
  src: string
  itemsCount: number
  ownersCount: number
  collectionName: string
  items: INFT[]
  floorPrice: any
}

interface IListing {
  token_id: string
  collections_addr: string
  market: string
  order_id: string
  created_at: number
  expiration: number
  price: number
  denom: string
  status: string
}

interface Props {
  children: ReactNode
}

type TCollectionFields = keyof ICollectionRequestResponse

const convertIPFStoSrc = (link: string) => {
  if (!link.match("ipfs://")) return link

  return link.replace("ipfs://", "https://d1mx8bduarpf8s.cloudfront.net/")
}

const NFTContextProvider = ({ children }: Props) => {
  const [tokens, setTokens] = useState([])
  const [pageInfo, setPageInfo] = useState({})
  const [collectionsMap, setcollectionsMap] = useState<{
    [key: string]: ICollection
  }>({})
  const [loading, setLoading] = useState(true)
  const [tokenListCount, setTokenListCount] = useState(0)
  const [nextPage, setNextPage] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [collectionsLoading, setCollectionsLoading] = useState(true)
  const [collectionBuilder] = useState(
    new gqlBuilder<ICollectionRequestParams, ICollectionRequestResponse>(
      "collection"
    ).addNestedObject("collections")
  )
  const [collections, setCollections] = useState<Record<string, ICollection>>(
    {}
  )
  const [hasNextCollectionPage, setHasNextCollectionPage] = useState(true)

  useEffect(() => {
    ; (async () => {
      const res = await axios.get(
        `${"https://phoenix-fcd.terra.dev"}/v1/tx/${"1628A233A8BCCD7EF124653558E36B910A6FB8AA5FC04BE6CC9EA5388C26043F"}`
      )
      console.log({ res })
    })()
  }, [])

  const collectionHasInfo = (address: string, fields: TCollectionFields[]) => {
    if (!collections[address]) return false

    return fields.every((item) => !!collections[address][item])
  }

  const queryCollection = async (
    address: string,
    fields: TCollectionFields[]
  ) => {
    // collectionBuilder.clear()

    collectionBuilder.setBuilder("collections")

    if (!collections[address])
      collectionBuilder
        .addField("collectionName")
        .addField("collectionAddr")
        .addField("floorPrice")
        .addField("src")
        .addField("bannerSrc")
        .addField("itemsCount")
        .addField("ownersCount")
        .addField("volume1d")
        .addField("volume7d")
        .addField("volume30d")
        .addField("denom")
        .addField("tokensTradeCount")

    fields.forEach((field) => {
      if (!collectionHasInfo(address, [field])) {
        collectionBuilder.addField(field)
      }
    })
    if (collectionBuilder.isEmpty()) return

    collectionBuilder.setParameter("collectionAddr", address)

    const response = await axios.post("https://nft-terra2.tfm.dev/graphql", {
      query: collectionBuilder.create(),
    })

    if (
      response.status !== 200 ||
      !response.data.data.collection.collections.length
    )
      return

    const temp = { ...collections }
    temp[address] = {
      ...(collections[address] || {}),
      ...response.data.data.collection.collections[0],
    }

    setCollections(temp)
  }

  const getNextTokens = async (
    collectionAddr = "",
    from?: number,
    to?: number,
    filterProps?: string
  ) => {
    if (!nextPage) {
      return
    }
    try {
      const query = `query MyQuery {
        token 
        ${`(${collectionAddr && `collectionAddr: "${collectionAddr}",`}
         limit: ${to || tokens.length + 24},
         offset: ${from || tokens.length},
              ${filterProps}
            )`}{
            pageInfo {
        count
        hasNextPage
        hasPreviousPage
        offset
      }
            tokens {
        collectionAddr
        imageUrl
        name
        price
        rank
        rarity
        tokenId
        price
        denom
        marketListing
        status
      }
    }
  }
    `
      const response = await axios.post("https://nft-terra2.tfm.dev/graphql", {
        query,
      })

      if (response) {
        setNextPage(response.data.data.token.pageInfo.hasNextPage)
        return response.data.data.token
      }
    } catch (e) {
      console.warn(e.message)
    }
  }

  const getTokenInfo = async (collectionAddr: string, tokenId: string) => {
    const response = await axios.post("https://nft-terra2.tfm.dev/graphql", {
      query: `query MyQuery {
    token(collectionAddr: "${collectionAddr}", tokenId: "${tokenId.toString()}") {
          pageInfo {
        count
        hasNextPage
        hasPreviousPage
        offset
      }
          tokens {
        collectionAddr
        imageUrl
        name
        rarity
        price
        tokenId
        traits
        rank
        denom
        listing
        marketListing
        status
        expiration
      }
    }
  }
    `,
    })
    if (!response) return

    setNextPage(response.data.data.token.pageInfo.hasNextPage)
    return response.data.data?.token.tokens
  }

  const getHistoricFloorPrice = async (
    collectionAddr: string,
    limit: number,
    resolutionSec: number,
    from: number,
    to: number
  ) => {
    let response
    const dataPathFloorPriceHistory = [
      "data",
      "data",
      "floorPrice",
      "floorPrices",
      "0",
      "floorPriceHistory",
    ]

    try {
      response = await axios.post(process.env.REACT_APP_NFT_GQL_API_TERRA2_NEW, {
        query: `query MyQuery {
        floorPrice(collectionAddr: "${collectionAddr}", limit: ${limit}, resolutionSec: ${resolutionSec}, , timeFrom: ${from}, timeTo: ${to}) {
            content {
            close
            high
            low
            open
            time
            volume
          }
        }
      }`,
      })
      // response = await axios.post(process.env.REACT_APP_NFT_GQL_API_TERRA2, {
      //   query: `query MyQuery {
      //     floorPrice(collectionAddr: "${collectionAddr}", limit: ${limit}, resolutionSec: ${resolutionSec}, , timeFrom: ${from}, timeTo: ${to}) {
      //       floorPrices {
      //         collectionAddr
      //         floorPriceHistory {
      //           floorPrice {
      //             close
      //             high
      //             low
      //             open
      //           }
      //           volume
      //           timestamp
      //           datetime
      //         }
      //       }
      //     }
      //   }`,
      // })
      console.log({ response })
    } catch (error) {
      console.warn(error)
    }

    if (!response) return
    // setNextPage(response.data.data.token.pageInfo.hasNextPage)
    // return path(dataPathFloorPriceHistory, response)
    return response?.data?.data?.floorPrice?.content
  }
  const getActivityListings = async (
    collectionAddr: string,
    limit: number,
    filters: ActivityFilters,
    page: number
  ) => {

    let response
    const dataPathResponse = ["data", "data", "listings", "listings"]
    const dataPathResponseHasNextPage = [
      "data",
      "data",
      "listings",
      "hasNextPage",
    ]

    try {
      response = await axios.post(process.env.REACT_APP_NFT_GQL_API_TERRA2, {
        query: `query MyQuery {
          listings(collectionAddr: "${collectionAddr}", limit: ${limit}, minPrice: ${filters?.minPrice ? filters?.minPrice * 10 ** 6 : null
          }, maxPrice: ${filters?.maxPrice ? filters?.maxPrice * 10 ** 6 : null
          }, maxRank: ${valueOrNull(filters?.maxRank)}, traits: "${filters?.traits?.length ? JSON.stringify(formatTraits(filters?.traits)) : null
          }", page: ${page}) {
            listings {
              collectionAddr
              createdAt
              denom
              duration
              imageURL
              name
              price
              rank
              soldAt
              tokenId
              traits
            }
            hasNextPage
          }
        }`,
      })
    } catch (error) {
      console.log("[getActivityListings]: error", error)
      console.warn(error)
    }

    if (!response) return
    // setNextPage(response.data.data.token.pageInfo.hasNextPage)
    return {
      data: path(dataPathResponse, response),
      hasNextPage: path(dataPathResponseHasNextPage, response),
    }
  }

  const getActivityTrades = async (
    collectionAddr: string,
    limit: number,
    filters: ActivityFilters,
    page: number
  ) => {
    let response
    const dataPathResponse = ["data", "data", "trades", "trades"]
    const dataPathResponseHasNextPage = [
      "data",
      "data",
      "trades",
      "hasNextPage",
    ]
    try {
      response = await axios.post(process.env.REACT_APP_NFT_GQL_API_TERRA2, {
        query: `query MyQuery {
          trades(collectionAddr: "${collectionAddr}", limit: ${limit}, minPrice: ${filters?.minPrice ? filters?.minPrice * 10 ** 6 : null
          }, maxPrice: ${filters?.maxPrice ? filters?.maxPrice * 10 ** 6 : null
          }, maxRank: ${valueOrNull(filters?.maxRank)}, traits: "${filters?.traits?.length ? JSON.stringify(formatTraits(filters?.traits)) : null
          }", page: ${page}) {
            trades {
              collectionAddr
              createdAt
              denom
              duration
              imageURL
              name
              price
              rank
              soldAt
              tokenId
              traits
            }
            hasNextPage
          }
        }`,
      })
    } catch (error) {
      console.warn(error)
    }

    if (!response) return
    // setNextPage(response.data.data.token.pageInfo.hasNextPage)
    return {
      data: path(dataPathResponse, response),
      hasNextPage: path(dataPathResponseHasNextPage, response),
    }
  }

  const getActivityAllNFTs = async (
    collectionAddr: string,
    limit: number,
    filters: ActivityFilters,
    page: number
  ) => {
    let response
    const dataPathResponse = ["data", "data", "allActivity", "all"]
    const dataPathResponsehasNextPage = [
      "data",
      "data",
      "allActivity",
      "hasNextPage",
    ]
    // TODO - add page, traits to the request params
    let typeAdaptedToService
    if (filters?.type === "All Nfts") {
      typeAdaptedToService = "all"
    } else if (filters?.type === "Listings") {
      typeAdaptedToService = "listing"
    } else if (filters?.type === "Trades") {
      typeAdaptedToService = "sale"
    }
    try {
      // ${filters?.maxPrice ? filters?.maxPrice * (10**6) : null}
      // allActivity(collectionAddr: "${collectionAddr}", limit: ${limit}, minPrice: ${filters?.minPrice ? filters?.minPrice * (10**6) : null}, maxPrice: ${filters?.maxPrice ? filters?.maxPrice * (10**6) : null}, maxRank: ${valueOrNull(filters?.maxRank)}, type: ${filters?.type}, page: ${page}) {
      response = await axios.post(process.env.REACT_APP_NFT_GQL_API_TERRA2, {
        query: `query MyQuery {
          allActivity(collectionAddr: "${collectionAddr}", limit: ${limit}, minPrice: ${filters?.minPrice ? filters?.minPrice * 10 ** 6 : null
          }, maxPrice: ${filters?.maxPrice ? filters?.maxPrice * 10 ** 6 : null
          }, maxRank: ${valueOrNull(filters?.maxRank)}, traits: "${filters?.traits?.length ? JSON.stringify(formatTraits(filters?.traits)) : null
          }", page: ${page}, type: "${typeAdaptedToService}") {
            all {
              collectionAddr
              createdAt
              denom
              duration
              imageURL
              name
              price
              rank
              soldAt
              tokenId
              traits
              type
            }
            hasNextPage
          }
        }`,
      })
    } catch (error) {
      console.warn(error)
    }

    if (!response) return
    // setNextPage(response.data.data.token.pageInfo.hasNextPage)
    return {
      data: path(dataPathResponse, response),
      hasNextPage: path(dataPathResponsehasNextPage, response),
    }
  }

  const getNFTStats = async (
    subService: string,
    collectionAddr: string,
    // limit: number,
    resolutionSec: number,
    timeFrom: number,
    timeTo: number
  ) => {
    let response
    const dataPathResponse = ["data", "data", "nftStats", subService]
    try {
      const query = subServiceToQuery(
        subService,
        collectionAddr,
        // limit,
        resolutionSec,
        timeFrom,
        timeTo
      )
      response = await axios.post(process.env.REACT_APP_NFT_GQL_API_TERRA2_NEW, {
        query,
      })
      console.log({ chart: response })
    } catch (error) {
      console.warn(error)
    }

    if (!response) return
    // setNextPage(response.data.data.token.pageInfo.hasNextPage)
    if (response?.data?.errors) {
      console.log("error", response?.data?.errors)
    }
    return {
      data: path(dataPathResponse, response),
    }
  }

  const getTokenListing = async (
    collectionAddress: string,
    tokenID: string
  ) => {
    const url = `https://nft.sspqf.org/listing/${collectionAddress}/${tokenID}`
    const response = (await axios.get(url)).data
    if (!response) return null
    return response
  }

  const getCollections = async (limit, offset, sorting, ASC, reset) => {
    const response = await axios.post(process.env.REACT_APP_NFT_GQL_API_TERRA2_NEW, {
      query: `query MyQuery {
      collection(sorting: "${sorting}", ASC: ${ASC}, limit: ${limit}, offset: ${offset}, withoutNullCount: true){
        pageInfo {
          count
          hasNextPage
        }
        collections{
          collectionAddr
          collectionName
          tokensTradeCount
          src
          bannerSrc
          floorPrice
          itemsCount
          ownersCount
          volume1d
          volume7d
          volume30d
          ownersCount
        }
       }    
    }
  `,
    })

    if (response.status !== 200) return
    let temp = { ...collections }
    if (reset) temp = {}

    response?.data?.data?.collection.collections.forEach((item) => {
      temp[item.collectionAddr] = item
    })
    setHasNextCollectionPage(
      response?.data?.data?.collection.pageInfo.hasNextPage
    )
    setCollections(temp)
    setCollectionsLoading(false)
  }

  const makeSearch = async (value) => {
    const response = await axios.post("https://nft-terra2.tfm.dev/graphql", {
      query: `query MyQuery {
        search(thatFind: "${value}", fullPhrase: true) {
          tokens {
            collectionAddr
            tokenId
            name
            denom
            price
            rank
            status
            marketListing
            imageUrl
          }
          collections {
            collectionAddr
            collectionName
            src
            ownersCount
            volume
            floorPrice
            itemsCount
          }
        }
      }
  `,
    })
    if (response.status !== 200) return

    return response?.data.data.search
  }

  const getOnePlanetAdditionalData = async (addr: string) => {
    const response = await axios.post("https://nft-terra2.tfm.dev/graphql", {
      query: `query MyQuery {
        oneplanetLookup(maker: "${addr}") {
          storages {
            maker
            storage
          }
        }
      }
      `,
    })
    if (response.status !== 200) return

    return response?.data.data.oneplanetLookup.storages
  }

  const getChartStats = async (collectionAddr: string, type: string, resolution: number) => {
    const response = await axios.post(process.env.REACT_APP_NFT_GQL_API_TERRA2_NEW, {
      query: `query chart_${type} {
        nftStats(collectionAddr:"${collectionAddr}", queryType:"${type}", resolution:${resolution}){
          content{
            timestamp
            value
          }
         }    
      }
    `
    })
    console.log({ response })
    if (!response?.data?.data?.nftStats?.content?.length) return []
    return response?.data?.data?.nftStats?.content
  }

  useLayoutEffect(() => {
    if (collections) setLoading(false)
  }, [collections, collectionsMap])

  return (
    <NftContext.Provider
      value={{
        nextPage,
        collections,
        loading,
        collectionsLoading,
        tokens,
        tokenListCount,
        hasNextCollectionPage,
        queryCollection,
        getCollections,
        getNextTokens,
        getTokenInfo,
        getHistoricFloorPrice,
        getActivityListings,
        getActivityTrades,
        getActivityAllNFTs,
        getNFTStats,
        getTokenListing,
        setNextPage,
        makeSearch,
        setCollections,
        getOnePlanetAdditionalData,
        getChartStats
      }}
    >
      {children}
    </NftContext.Provider>
  )
}

export default NftContext
export { NFTContextProvider }
