import React from 'react';

const CaretRightIcon = ({ width = '13', height = '12' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 2.25L8.75 6L5 9.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default CaretRightIcon
