
import React, { useState, useContext, useLayoutEffect, useEffect, useCallback, useRef, MutableRefObject } from "react";
import EarnIcon from "../icons/earn";
import LaunchIcon from "../icons/launch";
import TradeIcon from "../icons/trade";
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import AgreementPopup from "../agreementPopup";
import DotsIcon from "../icons/dots";
import ArrowDownIcon from "../icons/arrowDown";
import FilterButtonBar from "../NFT/filter/filterButtonBar";
import CreatedButtonBar from "../NFT/myNFT/createdButtonBar";
import Button from "components/platform/button";
import NFTFilterContext from "components/platform/context/nftFilter"
import NftIcon from "../icons/nft";

import MenuContext from "components/platform/context/menu";
import useWindowSize from "components/landing/hooks/useWindowSize";
import Analytics from "../icons/analytics";
import LogoIcon from "../icons/logo";
import { isMobileBrowserAgent } from "helpers/mobile";


const points: { text: string, href: string, icon: any }[] = [
  // {
  //   text: "Trade",
  //   href: "/trade",
  //   icon: <TradeIcon />
  // },
  // {
  //   text: "Earn",
  //   href: "/earn",
  //   icon: <EarnIcon />
  // },
  // {
  //   text: "Launch",
  //   href: "/launchpad",
  //   icon: <LaunchIcon />
  // }
  // , {
  //   text: "Analytics",
  //   href: "/analytics",
  //   icon: <Analytics />
  // },
  {
    text: "NFTs",
    href: "/nft",
    icon: <NftIcon />
  }
]
const extraPoints: { text: string, href: string, dropdownLinks: boolean }[] = [
  {
    text: "KYC",
    href: "/kyc",
    dropdownLinks: false
  }
]


const MobileMenu = ({ children }: { children: any }) => {
  const location = useLocation()
  const [extraOpen, setExtraOpen] = useState(false)
  const { openFilter, setOpenFilter } = useContext(NFTFilterContext)
  // const [expand, setExpand] = useState(false)
  const { isMobileMenuHidden, setIsMoblieMenuHidden } = useContext(MenuContext)
  const [isMobile, setIsMobile] = useState(false)
  const [prevHeight, setPrevHeight] = useState(0)

  const screenSize = useWindowSize()

  useLayoutEffect(() => {
    setIsMobile(isMobileBrowserAgent())
    setPrevHeight(screenSize.height)
  }, [])

  useEffect(() => {
    screenSize.height - prevHeight > 0 && setIsMoblieMenuHidden(false)
    setPrevHeight(screenSize.height)
  }, [screenSize])

  return (
    <div className="relative h-full ">
      <div className="h-full"
        onClick={() => {
          if (extraOpen) setExtraOpen(false)
        }}
      >
        {children}
      </div>
      <div className={`${isMobile && isMobileMenuHidden ? 'translate-y-full' : 'translate-y-0'}  transition-all fixed h-full grid-rows-fr-max isolate bottom-0 pointer-events-none w-full z-50 xl:hidden`}>
        <div className="isolate relative">
          <div id="bottomDrawer" className="absolute w-full h-full">

          </div>
          <div className="text-white p-0 md:pr-6 md:pb-6 flex justify-end items-end h-full z-full">
            <AgreementPopup />
          </div>
        </div>

        <div className="relative z-full isolate bg-theme-black w-full">
          <Switch>
            <Route path={"/nft/collections/:id/items"}>
              <FilterButtonBar />
            </Route>
            {/* <Route path={"/nft/activity"}>
              <FilterButtonBar />
            </Route> */}
          </Switch>
          {/* <div className="grid-container bg-theme-black relative">
            <div id="mobile-menu"
              className="flex py-3 justify-center col-span-full pointer-events-auto lg:hidden text-white bg-theme-black pt-1 pb-1  items-baseline w-full relative z-50 text-xss">
              <div className="flex items-center pr-6">
                <div className="flex items-center mr-1">
                  <LogoIcon width="16" height="20" />
                </div>
                <div className="text-lg font-centauri sm:block">
                  TFM
                </div>
              </div>
            </div>
          </div> */}

        </div>
      </div>
    </div>
  );
}

export default MobileMenu
