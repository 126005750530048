import React, { useEffect, useLayoutEffect, useMemo, useState } from "react"
import Container from "./layout/container"
import CloseIcon from "./icons/close"
import SwapArrowDown from "./icons/swapArrowDown"
// import Warning from "./icons/warning"
import { getSymbol, getTokenIcon, GetTokenSvg } from "../../helpers/token"
import useResult, { STATUS } from "../../hooks/useResult"
import Button from "components/platform/button"
import { format, decimal } from "../../libs/parse"
import Modal from "components/Modal"
import { formatAsset } from "../../libs/parse"
import { tokenInfos } from "../../rest/usePairs"
import { TooltipIcon } from "components/Tooltip"
import Tooltip from "lang/Tooltip.json"
import ErrorMessage from "components/platform/errorMessage"
import Loader from "components/landing/icons/loader"

import tokensDictionary from "constants/tokensDictionary.json"
import BigNumber from "bignumber.js"
import { getResult } from "helpers/getSwapResult"
import axios from "axios"
import { TransformMessageArray } from "./context/limit"
import { Coin, Coins, CreateTxOptions } from "@terra-money/terra.js"
import { ceil } from "libs/math"
import { useAddress } from "hooks"
import { useWallet } from "@terra-money/wallet-provider"
import LoaderIcon from "./icons/loader"

export enum ConfirmState {
    confirming,
    awaiting,
    pending,
    submited,
    error
}

interface ITsxDetails {
    minReceive: string
    amount: string
    price: string
}

const getTsxDetails = (message, token1: string, token2: string, slippage): ITsxDetails => {
    let minReceive
    let offer

    if (token1.match("terra1")) {
        const decoded = JSON.parse(atob(message.value.execute_msg.send.msg))

        minReceive = new BigNumber(decoded.execute_swap_operations.minimum_receive)
        offer = new BigNumber(decoded.execute_swap_operations.offer_amount)
    }
    else {
        minReceive = new BigNumber(message.value.execute_msg.execute_swap_operations.minimum_receive)
        offer = new BigNumber(message.value.execute_msg.execute_swap_operations.offer_amount)
    }

    minReceive = minReceive.dividedBy(10 ** tokensDictionary[token2].decimals)
    offer = offer.dividedBy(10 ** tokensDictionary[token1].decimals)

    const amount = minReceive.multipliedBy((1 + parseFloat(slippage)))
    const price = offer.dividedBy(amount)

    return {
        minReceive: minReceive.toFixed(tokensDictionary[token2].decimal),
        amount: amount.toFixed(tokensDictionary[token2].decimals),
        price: price.toFixed(tokensDictionary[token1].decimals)
    }
}


const ConfirmModal = (
    {
        confirmModal,
        slippageTolerance,
        formData,
        confirmationStep,
        setConfirmationStep,
        onFinish,
        fee,
        tax,
    }) => {
    const {
        value1,
        symbol1,
        value2,
        symbol2,
        token1,
        token2,
        feeValue,
        feeSymbol,
        gasPrice,
    } = formData

    const [fromInfo, setFromInfo] = useState("0")
    const [toInfo, setToInfo] = useState("0")
    const [feeInfo, setFeeInfo] = useState(["0"])
    const [spreadInfo, setSpreadInfo] = useState([])
    const [commissionInfo, setCommissionInfo] = useState([])
    const [taxInfo, setTaxInfo] = useState([])

    const [isParseFailed, setIsParseFailed] = useState(false)
    const [isFallbackFailed, setIsFallbackFailed] = useState(false)

    const [messageLoading, setMessageLoading] = useState(true)
    const [message, setMessage] = useState(undefined)
    const [tsxDetails, setTsxDetails] = useState<ITsxDetails>(undefined)

    const [error, setError] = useState(undefined)

    const walletAddress = useAddress()
    const { post: terraExtensionPost } = useWallet()

    const onClose = () => {
        confirmModal?.close()

        if (confirmationStep === ConfirmState.submited && onFinish)
            onFinish()

        setConfirmationStep(ConfirmState.confirming)
    }

    const { txInfo, status, setTxResult, errorMessage, link } = useResult()

    const executeMessage = async () => {
        setIsParseFailed(false)
        setIsFallbackFailed(false)

        try {
            const symbol = getSymbol(feeSymbol)
            const gas = fee.gas
            const amount = feeValue
            const feeCoins = new Coins({})
            feeCoins.set(symbol, ceil(amount))
            tax.split(",").every((item) => {
                if (item === "0") {
                    return false
                }
                const taxCoin = Coin.fromString(item)
                const feeCoin = feeCoins.get(taxCoin.denom)
                if (feeCoin === undefined) {
                    feeCoins.set(taxCoin.denom, taxCoin.amount)
                } else {
                    feeCoins.set(taxCoin.denom, feeCoin.amount.add(taxCoin.amount))
                }
                return true
            })

            const txOptions: CreateTxOptions = {
                msgs: TransformMessageArray([message]),
                memo: undefined,
                gasPrices: `${gasPrice}${getSymbol(feeSymbol)}`
            }

            setConfirmationStep(ConfirmState.pending)
            const extensionResult = await terraExtensionPost(txOptions)

            if (extensionResult) {
                setTxResult(extensionResult)
            }
        } catch (error) {
            // @ts-ignore
            setError(error)
            setConfirmationStep(ConfirmState.error)
        }
    }

    const getMessage = async () => {
        const decimals = tokensDictionary[token1]?.decimals || 6
        const calculatedAmount = new BigNumber(value1).multipliedBy(Math.pow(10, decimals))
        const slippage = slippageTolerance

        try {
            const response = await axios.get(`routing-api.tfm.dev/swap?token0=${token1}&token1=${token2}&amount=${calculatedAmount}&slipage=${slippage}`)

            if (response.status !== 200)
                throw new Error("Faild to load messages for contract");

            let responseMinReceive = ''

            if (token1.match("terra1"))
                responseMinReceive = JSON.parse(atob(response.data?.value?.execute_msg?.send.msg))?.execute_swap_operations?.minimum_receive
            else
                responseMinReceive = response.data?.value?.execute_msg?.execute_swap_operations?.minimum_receive

            if (!responseMinReceive || new BigNumber(responseMinReceive).eq(0))
                throw new Error("This transaction will not be executed - the minimum receive amount would be too low");

            let msgs = response.data

            if (`${token1}`.includes("terra1"))
                msgs.value.execute_msg.send.contract = "terra1enclyxhkuhqgn38grejum9fu0m3g4ztpmj7a22"
            else
                msgs.value.contract = "terra1enclyxhkuhqgn38grejum9fu0m3g4ztpmj7a22"
            msgs.value.sender = walletAddress


            setTsxDetails(getTsxDetails(msgs, token1, token2, slippage))
            setMessage(msgs)
            setMessageLoading(false)
        } catch (error) {
            setError(error)
            setConfirmationStep(ConfirmState.error)
        }
    }

    useLayoutEffect(() => {
        if (!txInfo || !token1 || !token2)
            return

        try {
            const result = getResult(txInfo)

            setToInfo(result.toAmount)
            setFromInfo(result.fromAmount)
            setSpreadInfo(result.spreads)
            setTaxInfo(result.taxes)
            setCommissionInfo(result.commisions)
            setFeeInfo(result.fee)
        } catch (fallbackError) {
            setIsFallbackFailed(true)
        }
    }, [txInfo])

    useEffect(() => {
        if (txInfo === null)
            return

        if (status === STATUS.SUCCESS)
            return setConfirmationStep(ConfirmState.submited)

        if (status === STATUS.FAILURE) {
            return setConfirmationStep(ConfirmState.error)
        }
    }, [status])

    useLayoutEffect(() => {
        if (confirmModal.isOpen)
            getMessage()
        else {
            setConfirmationStep(ConfirmState.confirming)
            setMessage(undefined)
            setMessageLoading(true)
            setIsParseFailed(false)
            setIsFallbackFailed(false)
            setTxResult(null)
            setError(undefined)
        }

    }, [confirmModal.isOpen])

    return (
        <Modal {...{ ...confirmModal, close: onClose }} className="" classNameOverlay="no-max-width">
            <Container className="w-screen">
                <div className="overflow-modal col-span-full md:col-start-2 md:col-span-6 lg:col-start-4 lg:col-span-6 bg-theme-black border-px border-black-80 rounded-2xl p-8 text-white font-poppins">
                    <div className="flex justify-between items-center">
                        <div className="text-xl">
                            {confirmationStep === ConfirmState.confirming && "Confirm Swap"}
                            {confirmationStep === ConfirmState.error && "Error"}
                        </div>
                        <div onClick={onClose} className="flex hover:text-theme-blue cursor-pointer transition-all">
                            <CloseIcon />
                        </div>
                    </div>
                    {confirmationStep === ConfirmState.confirming ? (
                        messageLoading
                            ?
                            <>
                                <div className="flex justify-center py-4 items-center">
                                    <div className="w-10 h-10">
                                        <LoaderIcon />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="flex mt-8 items-center">
                                    <img src={getTokenIcon(token1)} width={36} height={36} alt="" className="mr-2" />
                                    {(value1 ? value1 : "0") + " " + symbol1}
                                </div>
                                <div className="my-3 w-8 ml-0.5 flex justify-center">
                                    <SwapArrowDown />
                                </div>
                                <div className="flex items-center">
                                    <img src={getTokenIcon(token2)} width={36} height={36} alt="" className="mr-2" />
                                    {(tsxDetails ? tsxDetails.amount : "0") + " " + symbol2}
                                </div>
                                <p className="mt-6 text-2sm">If the price changes by more than {slippageTolerance * 100}% your transaction will revert.</p>
                                <div className="mt-6 text-sm">
                                    <div>{`Price: 1 ${symbol2} = ${tsxDetails ? tsxDetails.price : 0} ${symbol1}`}</div>
                                    <div className="mt-2">
                                        <TooltipIcon content={Tooltip.Swap.MinimumReceived}>
                                            Minimum Received: {(tsxDetails ? tsxDetails.minReceive : 0) + " " + symbol2}
                                        </TooltipIcon>
                                    </div>

                                </div>
                                <Button
                                    onClick={executeMessage}
                                    className="w-full mt-8">
                                    Confirm Swap
                                </Button>
                            </>) : null}
                    {confirmationStep === ConfirmState.awaiting ? (<>
                        <div className='text-center'>
                            <p className='mt-6 text-2xl'>Waiting for confirmation</p>
                            <p className='mt-2'>{`Swapping ${value1} ${symbol1} for ${value2} ${symbol2}`}</p>
                            <p className='mt-3 opacity-50 text-2sm'>Confirm this transaction in your wallet.</p>
                        </div>
                    </>) : null}
                    {confirmationStep === ConfirmState.pending ? (<>
                        <div>
                            <div className='text-center'>
                                <p className='mt-8 text-2xl'>Transaction is pending</p>
                                <a href={link} target="_blank" rel="noopener noreferrer" className='mt-2 terra-finder-link'>View on Terra Finder</a>
                            </div>
                            <div className="mt-4 flex justify-center">
                                <div className="w-16 h-16">
                                    <Loader />
                                </div>
                            </div>
                        </div>
                    </>) : null}
                    {confirmationStep === ConfirmState.submited ? (<>
                        <div>
                            <div className='text-center'>
                                <p className='mt-8 text-2xl'>Transaction was successful</p>
                                <a href={link} target="_blank" rel="noopener noreferrer" className='mt-2 terra-finder-link'>View on Terra Finder</a>
                            </div>
                            <div className="mt-6">
                                {!isFallbackFailed
                                    ? <>
                                        <div className="modal-result-row flex py-1">
                                            <span className="text-sm"  >From</span>
                                            <span className="ml-auto">{fromInfo}</span>
                                        </div>
                                        <div className="flex py-1">
                                            <span className="text-sm"  >To</span>
                                            <span className="ml-auto">{toInfo}</span>
                                        </div>
                                        {!isParseFailed &&
                                            <>
                                                <div className="pb-1 text-sm grid-cols-max-fr  items-block-start">
                                                    <TooltipIcon content="Fee paid due to the difference between market price and estimated price" >
                                                        <div className="opacity-40">
                                                            Spread
                                                        </div>
                                                    </TooltipIcon>
                                                    <div className="opacity-40">
                                                        {
                                                            spreadInfo.map((item) =>
                                                                <div className="text-right">{item.value} {item.symbol}</div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className="pb-1 text-sm grid-cols-max-fr  items-block-start">
                                                    <TooltipIcon content="Fee charged by the protocol, which is automatically deducted from the assets that the user receives" >
                                                        <div className="opacity-40">
                                                            Commission
                                                        </div>
                                                    </TooltipIcon>
                                                    <div className="opacity-40">
                                                        {
                                                            commissionInfo.map((item) =>
                                                                <div className="text-right">{item.value} {item.symbol}</div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="modal-result-row pb-1 grid-cols-max-fr items-block-start text-sm">
                                            <TooltipIcon content="The tax is a fixed percentage fee added to any market swap between Terra stablecoin denominations" >
                                                <div className="opacity-40">
                                                    Tax
                                                </div>
                                            </TooltipIcon>
                                            <div className="opacity-40">
                                                {
                                                    taxInfo.map((item) =>
                                                        <div className="text-right">{item.value} {item.symbol}</div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="flex py-1">
                                            <TooltipIcon className="text-sm" content="Fee paid to execute this transaction" >
                                                Fee
                                            </TooltipIcon>
                                            <span className="ml-auto">{feeInfo[0]}</span>
                                        </div>

                                    </>
                                    :
                                    <div>
                                        Please check <a href={link} target="_blank" rel="noopener noreferrer" className='terra-finder-link'>Terra Finder</a> to see more information about transaction
                                    </div>
                                }
                            </div>
                            <Button onClick={onClose} className="w-full mt-8 flex justify-center items-center">
                                Close
                            </Button>
                        </div>
                    </>) : null}
                    {confirmationStep === ConfirmState.error ? (<>
                        <ErrorMessage error={errorMessage || error} onClose={onClose} />
                    </>) : null}
                </div>
            </Container>
        </Modal>
    );
}

export default ConfirmModal
