import React, { lazy, Suspense } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
const StakingPage = lazy(() => import("./pages/Staking"));
const KnowYourCustomerPage = lazy(() => import("pages/knowYourCustomer"));
const KYCFinishPage = lazy(() => import("pages/KYCFinish"));
const TokenSalePage = lazy(() => import("pages/tokenSale"));
const TokenEarnPage = lazy(() => import("pages/tokenEarn"));
const DetailsBuilder = lazy(() => import("pages/DetailsBuilder"));
const StakeTokenPage = lazy(() => import("pages/stakeToken"));
const LandingPage = lazy(() => import("pages/landing"));
const TradeLayout = lazy(() => import("./components/platform/layout/trade"))
const AnalyticsPageLayout = lazy(() => import("./components/platform/layout/analytics"));
const BrandAssets = lazy(() => import("./pages/BrandAssets"));
const CreateAuctionPage = lazy(() => import("./pages/createAuction"))
const CreateLBPPage = lazy(() => import("./pages/createLBP"))
const LaunchLayout = lazy(() => import("components/platform/layout/launch"))
const thxPage = lazy(() => import("./pages/Thx"))
const LaunchpadPage = lazy(() => import("./pages/launchpad"))
const IDOsDetailsPage = lazy(() => import("./pages/IDOsDetails"))
const IDOsPage = lazy(() => import("./pages/IDOs"))
const NftLayout = lazy(() => import("components/platform/layout/nft"))
const VestingPage = lazy(() => import("./pages/vesting"));

export default () => (
  <Suspense fallback=''>
    <Switch>
      <Redirect exact from="/" to="/nft" />
      <Route exact path="/thx" component={thxPage} />
      <Route exact path="/landing" component={LandingPage} />
      <Route exact path="/launchpad" component={LaunchpadPage} />
      <Route exact path="/kyc" component={KnowYourCustomerPage} />
      <Route exact path="/kyc-finish" component={KYCFinishPage} />
      <Route exact path="/details/:id" component={DetailsBuilder} />
      <Route exact path="/ido/:id" component={TokenSalePage} />
      <Route path="/vesting/:id" component={VestingPage} />
      <Route exact path="/earn" component={StakingPage} />
      <Route path="/trade" component={TradeLayout} />
      <Route exact path="/earn/:id" component={StakeTokenPage} />
      <Route exact path="/funds/:id" component={TokenEarnPage} />
      <Route path="/analytics" component={AnalyticsPageLayout} />
      <Route exact path="/brand" component={BrandAssets} />
      {/* <Route exact path="/NFT" component={NFTPage} />
      <Route exact path="/NFT/:id" component={NFTDetails} />
      <Route exact path="/NFT/Collections/:id" component={CollectionBuilder} />
      <Route exact path="/NFT/Users/:id" component={NFTuserBuilder} />
      <Route exact path="/NFT/myNFTs/:id" component={CreatedNFTdetails} />
      <Route exact path="/IDOs" component={IDOsPage} />''
      <Route exact path="/IDOs/:id" component={IDOsDetailsPage} />''*/}
      <Route path='/discord' component={() => {
        window.location.href = 'https://discord.gg/8467CHCwKJ';
        return null;
      }}/>
      <Route exact path="/createAuction" component={CreateAuctionPage} />
      <Route exact path="/createLpb" component={CreateLBPPage} />
      <Route path="/nft" component={NftLayout} />
      <Route path="/launchpad" component={LaunchLayout} />
      <Redirect to="/" />
    </Switch>
  </Suspense>
)
