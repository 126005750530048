import { useWallet } from '@terra-money/wallet-provider';
import MyTokens from 'components/protrade/myTokens';
import { useAddress, useNetwork } from 'hooks';
import { truncate } from 'libs/text';
import React, { MutableRefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import Accordion from './accordion';
import ArrowUpIcon from './icons/arrowUp';
import CloseIcon from './icons/close';
import QuitIcon from './icons/quit';
import WalletIcon from './icons/wallet';
import BottomDrawer from './layout/bottomDrawer';

const WalletDropdown = () => {

  const [open, setOpen] = useState(false)

  const { name } = useNetwork()
  const wallet = useAddress()
  const { disconnect } = useWallet()

  const [myTokensOpen, setMyTokensOpen] = useState(false)

  const convertedName = useMemo(() => name[0].toUpperCase() + name.slice(1, name.length), [name])

  const toggle = useCallback(() => {
    setOpen(prev => !prev)
  }, [])

  const openMyTokens = useCallback(() => {
    setMyTokensOpen(true)
    setOpen(false)
  }, [])

  const handelDisconect = useCallback(() => { disconnect() }, [])

  const onClick = (e) => {
    if (e.target.closest("[data-wallet-dropdown]") === null) {
      setOpen(false)
    }
  }

  useLayoutEffect(() => {
    if (open) {
      window.addEventListener("click", onClick)
    }
    else {
      window.removeEventListener("click", onClick)
    }

    return () => {
      window.removeEventListener("click", onClick)
    }
  }, [open])

  return (
    <>
      <BottomDrawer open={myTokensOpen} setOpen={setMyTokensOpen}>
        <div className="flex h-full w-full items-end text-white">
          <div className="bg-theme-black w-full h-max-80-p pr-2 grid-rows-max-fr">
            <div className="flex justify-end mb-7 mt-5 pr-2">
              <div
                className="flex h-5 w-5 transition-all text-white hover:text-theme-blue cursor-pointer"
                onClick={() => setMyTokensOpen(false)}
              >
                <CloseIcon />
              </div>
            </div>
            <div className="overflow-y-auto pl-4 pr-2 scrollbar-tune">
              <MyTokens />
            </div>
          </div>
        </div>
      </BottomDrawer>
      <div className='relative'>
        <div className='flex flex-col items-end'>
          <div data-wallet-dropdown onClick={toggle} className='flex items-center group cursor-pointer'>
            <div className='flex transition-all rounded-lg border-px border-action p-2 group-hover:bg-black-75'>
              <WalletIcon />
            </div>
            <div className='transition-all duration-300 rounded-tr-lg rounded-br-lg py-0-5 px-1-5 text-action bg-black-80 group-hover:bg-black-75'>
              <div className={`flex transition-all ${open ? "" : "rotate-z-180"}`}>
                <ArrowUpIcon />
              </div>
            </div>
          </div>
        </div>
        <div className='absolute top-full right-0 pt-2 text-sm text-header-tab'>
          <Accordion {...{ open }}>
            <div className='py-1-5 select-none bg-black-75 rounded-base'>
              <div className='transition-all px-4 py-3 text-action w-max bg-nft-item'>
                {truncate(wallet)} | {convertedName}
              </div>
              <div onClick={handelDisconect} className='transition-all px-4 py-3 flex justify-between hover:bg-black-80 cursor-pointer'>
                <div>
                  Disconnect
                </div>
                <div className='flex'>
                  <QuitIcon />
                </div>
              </div>
            </div>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default WalletDropdown
