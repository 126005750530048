import React, { ReactNode, useState } from 'react';
import LoaderIcon from '../icons/loader';

const PageLoaderContext = React.createContext({
  setMessage: (arg: string) => { },
  setLoading: (arg: boolean) => { },
  loading: false
});

interface Props {
  children: ReactNode;
}

const PageLoaderContextProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("Creating LCD client");

  return (
    <PageLoaderContext.Provider
      value={{
        setMessage,
        setLoading,
        loading
      }}
    >
      {children}
      {loading &&
        <div className='fixed w-full h-full top-0 left-0 grid place-items-center text-white text-poppins bg-noise bg-theme-black'>
          <div className='bg-theme-black w-min-mobile border-px border-black-80 rounded-2xl p-4 md:p-8 flex flex-col items-center'>
            <div className='text-2xl mb-5'>
              Loading
            </div>
            <div className='h-9 w-9 mb-5'>
              <LoaderIcon />
            </div>
            <div>
              {message}
            </div>
          </div>
        </div>
      }
    </PageLoaderContext.Provider>
  );
};

export default PageLoaderContext;

export { PageLoaderContextProvider };