import axios from 'axios';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import TokenContext from 'layouts/TokenContext';

const SwapChartContext = React.createContext({
  data: null,
  isOpen: false,
  setIsOpen: (arg: boolean) => { },
  loading: false,
  timeFrameIndex: 0,
  setTimeFrameIndex: (arg: number) => { },
  isReversed: false,
  setIsReversed: (arg: boolean) => { },
  token0Info: null,
  setToken0Info: (arg: any) => { },
  token1Info: null,
  setToken1Info: (arg: any) => { },
  tokensMap: {},
  isNotificationOpen: true,
  setIsNotificationOpen: (arg: any) => { }
});

interface Props {
  children: ReactNode;
}

export const SwapChartTimeFrames = [
  {
    label: "24H",
    value: 1,
  },
  {
    label: "1W",
    value: 7,
  },
  {
    label: "1M",
    value: 30,
  },
  {
    label: "1Y",
    value: 365,
  },
]

const SwapChartContextProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [timeFrameIndex, setTimeFrameIndex] = useState(0)
  const [isNotificationOpen, setIsNotificationOpen] = useState(true)
  const [isReversed, setIsReversed] = useState(true)
  const [token0Info, setToken0Info] = useState<any>()
  const [token1Info, setToken1Info] = useState<any>(
    {
      contract_addr: "terra193c42lfwmlkasvcw22l9qqzc5q2dx208tkd7wl",
      decimals: 6,
      name: "Bitlocus Token",
      symbol: "BTL"
    })
  const [tokensMap, setTokensMap] = useState<{ [key: string]: any }>()
  const { pairsInfo } = useContext(TokenContext)

  const fetchAnalytics = async () => {
    if (!(token0Info && token1Info) && !Object.keys(pairsInfo).length)
      return setLoading(false)

    let token0 = token0Info?.contract_addr ?? 'terra193c42lfwmlkasvcw22l9qqzc5q2dx208tkd7wl'
    let token1 = token1Info?.contract_addr ?? 'uusd'

    const pair = pairsInfo[token0 + token1]
    console.log({ pairsInfo, pair, token0, token1 })

    if (!pair)
      return setLoading(false)

    const response = await axios.post('https://tfm.sspqf.org/graphql', {
      query: `query prices {
          pairPrice(contractAddr: "${pair.contractAddr}",
          start: ${Math.ceil(Date.now() / 1000) - 60 * 60 * 24 * SwapChartTimeFrames[timeFrameIndex].value}, finish: ${Math.ceil(Date.now() / 1000)}) {
          price
          timestamp
          priceInverted
        }
      }
    `
    })


    if (response.status === 200) {
      if (!response.data.data?.pairPrice) {
        setData(null)
        return setLoading(false)
      }
      if (SwapChartTimeFrames[timeFrameIndex].value >= 30) {
        setData(response.data.data.pairPrice.filter(item => item.timestamp % 24 === 0))
        return setLoading(false)
      }


      pair.token0Address === token0 && setIsReversed(true)
      setData(response.data.data.pairPrice)
      return setLoading(false)
    }

    setData(null)
    return setLoading(false)
  }

  useEffect(() => {
    if (token0Info && token1Info && Object.keys(pairsInfo).length) {
      setLoading(true)
      fetchAnalytics()
    }
  }, [timeFrameIndex, token0Info, token1Info, pairsInfo])

  useEffect(() => {
    const output = {}

    if (token0Info)
      output[token0Info.contract_addr] = token0Info

    if (token1Info)
      output[token1Info.contract_addr] = token1Info

    setTokensMap(output)

  }, [token0Info, token1Info])

  return (
    <SwapChartContext.Provider
      value={{
        data,
        isOpen,
        setIsOpen,
        loading,
        timeFrameIndex,
        setTimeFrameIndex,
        isReversed,
        setIsReversed,
        token0Info,
        setToken0Info,
        token1Info,
        setToken1Info,
        tokensMap,
        isNotificationOpen,
        setIsNotificationOpen
      }}
    >
      {children}
    </SwapChartContext.Provider>
  );
};

export default SwapChartContext;

export { SwapChartContextProvider };
