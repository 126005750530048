import { useAddress, useConnectModal } from 'hooks';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import useAPI from 'rest/useAPI';
import useBalance from 'rest/useBalance';
import tokensDictionary from "constants/tokensDictionary.json"
import BigNumber from 'bignumber.js';
import ButtonSecondary from 'components/platform/buttonSecondary';
import CaretLeftIcon from 'components/platform/icons/caretLeft';
import CaretRightIcon from 'components/platform/icons/caretRight';
import LoaderIcon from 'components/platform/icons/loader';
import useDebounce from 'components/landing/hooks/useDebounce';
import SkeletonLoading from 'components/platform/layout/SkeletonLoading';
import MyTokenLine from "./MyTokenLine"
import Button from 'components/platform/button';
import axios from 'axios';

const perPage = 10

const MyTokens = ({ className = "" }) => {
  const address = useAddress()

  const { loadDenomBalance, loadContractBalance } = useAPI()

  const [balances, setBalances] = useState([])
  const [loading, setLoading] = useState(true)

  const [page, setPage] = useState(0);
  const [debouncePage] = useDebounce<number>(page, 300);
  const [maxPage, setMaxPage] = useState(1);
  const [dummy, setDummy] = useState([])

  const connectModal = useConnectModal()

  const openConnect = useCallback(() => connectModal.open(), [])

  useEffect(() => {
    if (!balances.length) return;

    setMaxPage(Math.ceil(balances.length / perPage));
  }, [balances]);

  const getNativeBalance = async () => {
    const denomInfos = await loadDenomBalance()
    let temp = []

    if (denomInfos !== undefined) {
      denomInfos.forEach((denomInfo) => {
        temp.push({
          address: denomInfo.denom.toLocaleLowerCase(),
          amount: denomInfo.amount
        })
      })
    }

    return temp
  }

  const getBalance = useCallback((tokenAddress, address) => {
    return (`${tokenAddress}: WasmContractsContractAddressStore(
      ContractAddress:"${tokenAddress}",
      QueryMsg: "{\\"balance\\":{\\"address\\":\\"${address}\\"}}")
    {
      Result
    }`)
  }, [])

  const updateBalances = async () => {
    if (!address) return

    const native = await getNativeBalance()

    let queries = ""

    Object
      .keys(tokensDictionary)
      .forEach((item) => {
        if (item.match("(terra1)"))
          queries += getBalance(item, address) + " "
      })


    const response = await axios.post("https://mantle.terra.dev/", { query: "{" + queries + "}" })

    if (response.status !== 200 && !response.data)
      return setLoading(false)

    const tokens = []
    Object.keys(response.data.data).filter(item => !response.data.data[item]?.Result.match(/:"0"/)).forEach((item) => {
      if (response?.data?.data[item])
        tokens.push({
          address: item.toLocaleLowerCase(),
          amount: JSON.parse(response.data.data[item].Result).balance
        })
    })

    const result = native.concat(tokens)
    setBalances(result)
    setLoading(false)

    const dummyAmount = perPage - (result.length % perPage)
    setDummy(new Array(dummyAmount !== 5 ? dummyAmount : 0).fill(undefined))
  }

  useLayoutEffect(() => {
    if (!address) return setLoading(false)

    setLoading(true)
    updateBalances()
  }, [address])

  return (
    <div className={className}>
      <div className='flex items-center justify-between mb-6 flex-wrap'>
        <p className="text-xl w-max">My tokens</p>
        {/* <div className='grid-cols-max-fr p-2-5 gap-1 border-px border-header-tab rounded-lg text-header-tab'>
          <div>
            Estimated balance:
          </div>
          <div className='w-max flex place-self-end'>
            <div>
              $
            </div>
            <div className='text-action'>
              <SkeletonLoading className='w-min-16' loading={loading}>
                {address ? 500 : 0}
              </SkeletonLoading>
            </div>
          </div>
        </div> */}
      </div>
      {
        loading ?
          <div className='flex justify-center items-center '>
            <div className='w-10 h-10'>
              <LoaderIcon />
            </div>
          </div>
          :
          <>
            <div>
              {/* <div className='grid grid-cols-3 text-xs text-header-tab gap-4 mb-6'> */}
              <div className='grid-cols-max-fr text-xs text-header-tab gap-4 mb-6'>
                <div>
                  Tokens
                </div>
                <div className='place-self-end'>
                  Token amount
                </div>
                {/* <div className='place-self-end'>
                  Balance
                </div> */}
              </div>
            </div>
            {!address ?
              <Button className='w-full' onClick={openConnect}>
                Connect Wallet
              </Button>
              :
              <>
                <div className='-my-1-5'>
                  {balances
                    .slice(perPage * debouncePage, perPage * debouncePage + perPage)
                    .map((item, index) => (
                      <MyTokenLine key={item.address}{...item} />
                    ))}
                  {
                    balances.length > perPage + 1 ? (
                      <div>
                        {
                          debouncePage === maxPage - 1 &&
                          dummy.map(() =>
                            <div className='w-full text-sm gap-4 my-1-5 py-3'>
                              <div className='ml-4 mr-2 w-5 h-5 rounded-full' />
                            </div>
                          )
                        }
                      </div>
                    ) : null
                  }
                </div>
                {balances.length === 0 && (
                  <div className={`text-center px-8 py-4`}>
                    No Tokens found
                  </div>
                )}
                {
                  balances.length > perPage + 1 ? (
                    <div className="flex justify-center px-8 items-center">
                      <ButtonSecondary
                        onClick={() => setPage(Math.max(0, page - 1))}
                        disabled={page === 0}
                        className="p-1 flex rounded-base"
                        roundedOff
                        paddingOff
                      >
                        <CaretLeftIcon />
                      </ButtonSecondary>
                      <div className="mx-3">
                        Page {page + 1} of {maxPage}
                      </div>
                      <ButtonSecondary
                        onClick={() => setPage(Math.min(maxPage - 1, page + 1))}
                        disabled={page === maxPage - 1}
                        className="p-1 flex rounded-base"
                        roundedOff
                        paddingOff
                      >
                        <CaretRightIcon />
                      </ButtonSecondary>
                    </div>
                  ) : null
                }
              </>
            }
          </>
      }
    </div >
  );
}

export default MyTokens