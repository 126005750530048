import axios from "axios";
import React, {ReactNode, useEffect, useState } from "react";
import moment from 'moment'

const ProjectsContext = React.createContext<
    {
        projects: {}
        setProjects: React.Dispatch<any>,
        active: {}
        setActive: React.Dispatch<any>,
        upcoming: {}
        setUpcoming: React.Dispatch<any>,
        ended: {}
        setEnded: React.Dispatch<any>,
        statistic: {}
        setStatistic: React.Dispatch<any>,
        fetching: boolean,
        setFetching: React.Dispatch<any>,
    }
>({
    projects: {},
    setProjects: () => { },
    active: {},
    setActive: () => { },
    upcoming: {},
    setUpcoming: () => { },
    ended: {},
    setEnded: () => { },
    statistic: {},
    setStatistic: () => { },
    fetching: false,
    setFetching: () => { },
});

interface Props {
    children: ReactNode;
}

const ProjectsContextProvider = ({ children }: Props) => {
    const [projects, setProjects] = useState({});
    const [active, setActive] = useState({})
    const [upcoming, setUpcoming] = useState({})
    const [ended, setEnded] = useState({})
    const [done, setDone] = useState(false)
    const [statistic, setStatistic] = useState({})
    const [fetching, setFetching] = useState(false)

    const getProjects = async () => {
        try {
            const response = await axios.get('https://ico.sspqf.org/project/')
            if (response) {
                let projectNumber = 0
                const temp = {}
                const tempActive = {}
                const tempUpcoming = {}
                const tempEnded = {}
                for (let item in response.data) {
                    const project = {
                        key: response.data[item].project_id,
                        icon: response.data[item].project_logo.url,
                        name: response.data[item].project,
                        intro: response.data[item].about_project,
                        symbol: response.data[item].icon?.url,
                        video: response.data[item].main_attachment.url,
                        category: response.data[item].category,
                        received: response.data[item].received,
                        goal: response.data[item].goal,
                        endDate: response.data[item].end_date,
                        socials: response.data[item].social,
                        tokenSale: response.data[item].token_sale,
                        whitelist: response.data[item].whitelist,
                        totalTokens: response.data[item].total_tokens,
						cant_particapate: response.data[item].cant_particapate,
                        bounty: response.data[item].bounty,
                        bonus: response.data[item].bonus,
                        KYC: response.data[item].kyc,
                        additionalLinks: response.data[item].additional_links,
                        whitepaperHref: response.data[item].whitepaper,
                        ticker: response.data[item].ticker,
                        tokenPrice: response.data[item].ico_token_price,
                        tokenType: response.data[item].token_type,
                        interest: response.data[item].interest,
                        status: response.data[item].status,
                        accepts: response.data[item].accepts,
                        note: response.data[item].token_sale[0].type !== '' ? `Public Sale: ${response.data[item].token_sale[0].type},\n${response.data[item].ico_token_price}` : `${response.data[item].ico_token_price}`,
                        vesting: {
                            start: moment(response.data[item].start_date).format("X"),
                            end: moment(response.data[item].end_date).format("X"),
                        },
                        screenshots: response.data[item].screenshots,
                        roleOfToken: response.data[item].short_review,
                        marketPrice: 0,
                        dayChange: 0,
                        dayVolume: 0,
                        marketCap: 0,
                        ICOprice: 0,
                        USDroi: 0,
                        ETHroi: 0,
                        BTCroi: 0,
                        raised: response.data[item].received,
                        month: response.data[item].end_date,
                    }
                    projectNumber += 1
                    temp[`project${projectNumber}`] = project
                    setProjects({...projects, ...temp})
                    if (response.data[item].status === 'active') {
                        tempActive[`project${projectNumber}`] = project
                        setActive({...active, ...tempActive})
                    }
                    if (response.data[item].status === 'upcoming') {
                        tempUpcoming[`project${projectNumber}`] = project
                        setUpcoming({...upcoming, ...tempUpcoming})
                    }
                    if (response.data[item].status === 'ended') {
                        tempEnded[`project${projectNumber}`] = project
                        setEnded({...ended, ...tempEnded})
                    }
                }
                setDone(true)
            }
        } catch (e) {
            console.warn(e.message)
        }
    }

    const getStatistic = async () => {
        setFetching(true)
            const tmp = {...ended}
            for (let item of Object.keys(tmp)) {
                try {
                    const statistic = await axios.get(`https://api.coingecko.com/api/v3/coins/${tmp[item].name.toLowerCase().replace(' ', '-')}`)
                    if (statistic.status === 200) {
                        tmp[item].marketPrice = statistic.data.market_data.current_price.usd.toFixed(6)
                        tmp[item].dayChange = statistic.data.market_data.price_change_percentage_24h.toFixed(2)
                        tmp[item].dayVolume = statistic.data.market_data.total_volume.usd.toFixed(0)
                        tmp[item].marketCap = statistic.data.market_data.market_cap.usd.toFixed(0)
                        tmp[item].ICOprice = statistic.data.ico_data?.quote_public_sale_amount ? statistic.data.ico_data.quote_public_sale_amount : 0
                        tmp[item].USDroi = statistic.data.market_data.roi?.currency === 'usd' ? statistic.data.market_data.roi.times.toFixed(2) : 0
                        tmp[item].ETHroi = statistic.data.market_data.roi?.currency === 'eth' ? statistic.data.market_data.roi.times.toFixed(2) : 0
                        tmp[item].BTCroi = statistic.data.market_data.roi?.currency === 'btc' ? statistic.data.market_data.roi.times.toFixed(2) : 0
                    }
                } catch (e) {
                    delete tmp[item]
                }
            }
            setStatistic(tmp)
        setFetching(false)
    }

    useEffect(() => {
        getProjects()
    }, [])

    useEffect(() => {
        if (done) {
            // getStatistic()
        }
    }, [done])

    return (
        <ProjectsContext.Provider
            value={{
                projects,
                setProjects,
                active,
                setActive,
                upcoming,
                setUpcoming,
                ended,
                setEnded,
                statistic,
                setStatistic,
                fetching,
                setFetching,
            }}
        >
            {children}
        </ProjectsContext.Provider>
    );
};

export default ProjectsContext;

export { ProjectsContextProvider };
