import React, { useEffect, useRef, useState } from "react"
import { MAX_TX_POLLING_RETRY, TX_POLLING_INTERVAL } from "constants/constants"
import MESSAGE from "lang/MESSAGE.json"
import { useNetwork } from "hooks"
import {
    CreateTxFailed,
    TxFailed,
    TxResult,
    TxUnspecifiedError,
    UserDenied,
} from "@terra-dev/wallet-types"
import axios from "rest/request"
export interface ResultProps {
    response?: TxResult
    error?: UserDenied | CreateTxFailed | TxFailed | TxUnspecifiedError | Error
    onFailure: () => void
    parserKey: string
}

export enum STATUS {
    SUCCESS = "success",
    LOADING = "loading",
    FAILURE = "failure",
}

const useResult = () => {
    const [result, setTxResult] = useState<TxResult | undefined>()
    const [txInfo, setTxInfo] = useState<SwapTxInfo>()

    const [errorMessage, setErrorMessage] = useState("")
    const [link, setLink] = useState("")

    const [status, setStatus] = useState<STATUS>(STATUS.LOADING)
    const { fcd, finder, phoenixFcd } = useNetwork()

    const retryCount = useRef(0)

    useEffect(() => {
        const load = async () => {
            const txHash = result?.result?.txhash ?? ""
            const raw_log = result?.result?.raw_log ?? ""
            if (!txHash) {
                setStatus(STATUS.FAILURE)
                // @ts-ignore
                setErrorMessage(result)
                return
            }
            setLink(finder(txHash, "tx"))
            try {
                const { data: res } = await axios.get(`${phoenixFcd}/v1/tx/${txHash}`)
                if (res?.code) {
                    console.log("Error, response", res)

                    setTxInfo(res)
                    setStatus(STATUS.FAILURE)
                    setErrorMessage(res?.raw_log.replace(/^.*index: 0: /gm, "") || `Transaction failed with code ${res?.code}`)
                    return
                }
                if (res?.txhash) {
                    setTxInfo(res)
                    setStatus(STATUS.SUCCESS)
                    setErrorMessage("")
                }
            } catch (error) {
                if (retryCount.current >= MAX_TX_POLLING_RETRY) {
                    setStatus(STATUS.FAILURE)
                    setErrorMessage("")
                    retryCount.current = 0
                    return
                }
                retryCount.current += 1
                setTimeout(() => {
                    setErrorMessage("")
                    setStatus(STATUS.LOADING)
                    load()
                }, TX_POLLING_INTERVAL)
            }
        }

        if (result != null) {
            load()
            setStatus(STATUS.LOADING)
            setErrorMessage("")
            retryCount.current = 0
        }

        if (result === null) {
            setErrorMessage("")
            setStatus(STATUS.LOADING)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result])
    return { txInfo, status, setTxResult, errorMessage, link }
}

export default useResult
