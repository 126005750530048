import TelegramIcon from "../icons/telegram";
import TwitterIcon from "../icons/twitter";
import Container from "./container";
import React from "react";
import { Link } from "react-router-dom";
import useTranslate from "components/landing/hooks/useTranslate";
import SubscribeInput from "components/landing/inputs/subscribeInput";
import MediumIcon from "../icons/medium";
import DiscordIcon from '../icons/discord'

const Footer = () => {
  const { translated } = useTranslate('footer');

  return (
    <div id="footer" className="bg-theme-black pt-8">
      {/* <Container className="md:mb-8">
        <div className="col-span-full md:col-span-4 lg:col-span-6 ">
          <span>{translated("email1")}</span>
          <SubscribeInput placeholder={translated("email2")} />
        </div>
        <div className="col-span-full md:col-span-4 lg:col-span-6 md:block hidden">
          <div className="flex justify-end social">
            <a href="https://twitter.com/terraformer" target="_blank" className="block mr-4 h-10 social-link">
              <TwitterIcon prefix="footer" />
            </a>
            <a href="https://t.me/terraformer" target="_blank" className="block h-10 mr-4 social-link">
              <TelegramIcon prefix="footer" />
            </a>
            <a href="https://medium.com/@tfm.com/" target="_blank" className="block h-10 mr-4 social-link">
              <MediumIcon prefix="footer" />
            </a>
            <a href="https://discord.gg/K5wppnJM22" target="_blank" className="block h-10 social-link">
              <DiscordIcon prefix="footer" />
            </a>

          </div>
        </div>
      </Container>
      <Container>
        <div className="col-span-full flex justify-center social mb-5 mt-8 md:hidden">
          <a href="https://twitter.com/terraformer" target="_blank" className="block h-10 mr-4 social-link">
            <TwitterIcon prefix="footer" />
          </a>
          <a href="https://t.me/terraformer" target="_blank" className="block h-10 mr-4 social-link">
            <TelegramIcon prefix="footer" />
          </a>
          <a href="https://medium.com/@tfm.com/" target="_blank" className="block h-10 mr-4 social-link">
            <MediumIcon prefix="footer" />
          </a>
          <a href="https://discord.gg/K5wppnJM22" target="_blank" className="block h-10 social-link">
            <DiscordIcon prefix="footer" />
          </a>


        </div>
      </Container>
      <div className="shadow-inset-top">
        <Container >
          <div className="col-span-full text-center -mx-2 sm:flex pt-4 pb-20 sm:pb-8">

          </div>
        </Container>
      </div> */}
    </div>
  );
}

export default Footer
