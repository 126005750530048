import LoaderIcon from "components/platform/icons/loader";
import PlayIcon from "components/platform/icons/playIcon";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";

const ImageChecker = ({ url, className = '', loaderHeight = 'h-10', noPlayButton = false, containerClassName = '', bgClassName = 'bg-black-80', delay = null }) => {
    const [loading, setLoading] = useState(true)
    const [isVideo, setIsVideo] = useState(false)
    const [showVideo, setShowVideo] = useState(false)
    const container = useRef() as MutableRefObject<HTMLDivElement>

    const doMagic = () => {
        const image = new Image
        image.src = url
        if (!url.includes('cloudflare') && !url.includes('talis')) {
            if (image.height < image.width) {
                image.classList.add('mediacontent-center', 'rounded-lg', 'object-fit-cover', 'h-full')
            } else {
                image.classList.add(className, 'rounded-lg')
            }
            container?.current?.append(image); setLoading(false)
            return
        }


        image.onload = () => {
            if (!container.current)
                return

            if (image.height < image.width) {
                image.classList.add('mediacontent-center', 'rounded-lg', 'object-fit-cover', 'h-full')
            } else {
                image.classList.add(className, 'rounded-lg')
            }
            container?.current?.append(image); setLoading(false)
        }
        image.onerror = () => { setTimeout(() => { doMagic() }, 700) }
    }

    // const getVideoPreview = () => {
    //     const canvas = document.createElement("canvas");
    //     const video = document.createElement('video')
    //     let scale = 1
    //     video.setAttribute('src', url)
    //     // video.load()

    //     video.onloadedmetadata = () => {
    //         if (!container.current)
    //             return
    //         // setTimeout(() => {
    //             video.currentTime = 2.0;

    //         // }, 200);

    //         canvas.width = video.videoWidth * scale;
    //         canvas.height = video.videoHeight * scale;

    //     }
    //     video.oncanplaythrough = ()=>{
    //         canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    //         // const img = document.createElement("img");
    //         // img.src = canvas.toDataURL();
    //         canvas.classList.add('mediacontent-center', 'object-fit-cover', 'h-full')
    //         container.current.append(canvas);
    //     }
    //     video.onerror = () => { setTimeout(() => { getVideoPreview() }, 700) }

    // }

    useEffect(() => {
        if (!url)
            return

        if (url.includes('.mp4')) {
            setIsVideo(true)
            setLoading(false)
            return
        }
        setTimeout(() => {
            doMagic()
        }, delay)
    }, [])

    return (
        <div ref={container} className={` relative  overflow-hidden ${bgClassName} ${containerClassName ? containerClassName : 'w-full h-full'}`}>
            {
                loading &&
                <div className="w-full h-full flex items-center justify-center rounded-lg ">
                    <div className={loaderHeight}>
                        <LoaderIcon />
                    </div>
                </div>
            }
            {
                isVideo && !showVideo && !noPlayButton &&
                <div className="w-full h-full flex items-center justify-center rounded-lg ">
                    <div className='py-4 pl-5 pr-3  rounded-full flex items-center justify-center bg-play-button hover:bg-black-60 blur-40 cursor-pointer '
                        onClick={(e) => { e.preventDefault(); setShowVideo(true) }}
                    >
                        <PlayIcon />
                    </div>
                </div>
            }
            {
                isVideo && showVideo &&
                <video className={'w-full h-full rounded-lg '} controls controlsList="nodownload" autoPlay >
                    <source src={url} />
                </video>
            }
        </div>
    )
}
export default ImageChecker