import React, { useContext } from "react";
import Button from "../../button";
import NFTFilterContext from "components/platform/context/nftFilter"
import ButtonSecondary from "components/platform/buttonSecondary";

const FilterButtonBar = () => {
    const { openFilter, setOpenFilter } = useContext(NFTFilterContext)
    const { visible, setVisible, removeSearchPath } = useContext(NFTFilterContext)

    return (
        <div className={`w-full pointer-events-auto z-10 transition-all block lg:hidden ${!visible && 'hidden'}`}>
            {
                (
                    <div className="shadow-bottom-px bg-theme-black text-white shadow-bottom-px py-3 px-4 text-center w-full text-base">
                        {
                            !openFilter ?
                                <Button paddingOff
                                    onClick={() => {
                                        const body = document.body
                                        body.style.overflow = 'hidden'
                                        setOpenFilter(true)

                                    }
                                    }
                                    className="w-full flex justify-center p-2 font-500 items-center">
                                    Filter
                                </Button>
                                :
                                <div className="flex w-full gap-x-4 items-center justify-between">
                                    <ButtonSecondary paddingOff className="w-full flex justify-center p-2 font-500 items-center"
                                        onClick={() => {
                                            const body = document.body
                                            body.style.overflow = 'auto'
                                            removeSearchPath()
                                        }
                                        }
                                    >Clear All</ButtonSecondary>
                                    <Button paddingOff onClick={() => {
                                        const body = document.body
                                        body.style.overflow = 'auto'
                                        setOpenFilter(false)
                                    }} className="w-full flex justify-center p-2 font-500 items-center">
                                        Done
                                    </Button>
                                </div>
                        }
                    </div>

                )
            }
        </div>
    );
}

export default FilterButtonBar
