import tokensDictionary from "constants/tokensDictionary.json"
import BigNumber from "bignumber.js"
import { formatAsset } from "libs/parse"

interface SwapParameter {
  symbol: string
  value: string
}

interface SwapTransactionResultMinified {
  amount: {
    to: string
    from: string
  }
  fee: string[]
}
interface SwapTransactionResult extends SwapTransactionResultMinified {
  spreads: SwapParameter[]
  taxes: SwapParameter[]
  commisions: SwapParameter[]
}

function isNative(token) {
  if (!token) throw new Error("token is undefined")

  return !token?.match("terra1")
}

function formatAmount(value, address) {
  return new BigNumber(value)
    .div(Math.pow(10, tokensDictionary[address]?.decimals || 6))
    .toFixed(tokensDictionary[address]?.decimals || 6)
}

function splitToObjects(attributes, splitKey) {
  if (!attributes) return null

  const output = []
  let temp = {}

  for (let attr of attributes) {
    if (attr.key === splitKey && Object.keys(temp).length) {
      output.push({ ...temp })

      temp = {}

      temp[attr.key] = attr.value

      continue
    }

    temp[attr.key] = attr.value
  }
  if (Object.keys(temp).length) output.push({ ...temp })

  return output
}

function makeSwapObjects(attributes) {
  return splitToObjects(attributes, "offer")
}

function makeActionObjects(attributes) {
  const result = splitToObjects(attributes, "action")

  if (!result) return result

  return result.filter(({ action }) => action === "swap")
}

function getOpperations(tx) {
  let operations = tx?.value?.msg[0]?.value?.execute_msg?.execute_swap_operations?.operations

  if (!operations) {
    const msg = tx?.value?.msg[0]?.value?.execute_msg?.send?.msg

    if (!msg) return null

    const decoded = JSON.parse(atob(msg))

    operations = decoded.execute_swap_operations.operations
  }

  return operations
}

function getAssetAddress(info) {
  return info?.token?.contract_addr || info?.native_token?.denom
}

function splitCoint(value) {
  const match = value.match(/(\d+)(.+)/)
  return match ? { amount: match[1], address: match[2] } : null
}

// { "key": "offer", "value": "10000000ukrw" },
// { "key": "trader", "value": "terra1uwp7l2wlmk4vgg225pzqjzgdtuukywyhr48s0g" },
// { "key": "recipient", "value": "terra1uwp7l2wlmk4vgg225pzqjzgdtuukywyhr48s0g" },
// { "key": "swap_coin", "value": "8116uusd" },
// { "key": "swap_fee", "value": "28uusd" }

function getNativeSwapOperation(operation, swaps) {
  const askAsset = operation.ask_denom
  const offerAsset = operation.offer_denom

  const transaction = swaps.find(
    ({ offer, swap_coin }) => splitCoint(offer).address === offerAsset && splitCoint(swap_coin).address === askAsset
  )

  const tax = {
    symbol: tokensDictionary[askAsset]?.symbol || "N/D",
    value: formatAmount(splitCoint(transaction.swap_fee).amount, askAsset),
  }

  const fromAmount = `${formatAmount(splitCoint(transaction.offer).amount, offerAsset) || "0"} ${
    tokensDictionary[offerAsset]?.symbol || "N/D"
  }`

  const toAmount = `${formatAmount(splitCoint(transaction.swap_coin).amount, askAsset) || "0"} ${
    tokensDictionary[askAsset]?.symbol || "N/D"
  }`

  return {
    askAsset,
    offerAsset,
    tax,
    fromAmount,
    toAmount,
  }
}

function getCustomSwapOperation(operation, swaps) {
  const askAsset = getAssetAddress(operation.ask_asset_info)
  const offerAsset = getAssetAddress(operation.offer_asset_info)

  const transaction = swaps.find(({ offer_asset, ask_asset }) => offer_asset === offerAsset && ask_asset === askAsset)

  const tax = {
    symbol: tokensDictionary[transaction.ask_asset]?.symbol || "N/D",
    value: formatAmount(transaction.tax_amount, transaction.ask_asset),
  }

  const commision = {
    symbol: tokensDictionary[transaction.ask_asset]?.symbol || "N/D",
    value: formatAmount(transaction.commission_amount, transaction.ask_asset),
  }

  const spread = {
    symbol: tokensDictionary[transaction.ask_asset]?.symbol || "N/D",
    value: formatAmount(transaction.spread_amount, transaction.ask_asset),
  }

  const fromAmount = `${formatAmount(transaction?.offer_amount, transaction?.offer_asset) || "0"} ${
    tokensDictionary[transaction.offer_asset]?.symbol || "N/D"
  }`

  const toAmount = `${formatAmount(transaction?.return_amount, transaction?.ask_asset) || "0"} ${
    tokensDictionary[transaction.ask_asset]?.symbol || "N/D"
  }`

  return {
    askAsset,
    offerAsset,
    tax,
    commision,
    spread,
    fromAmount,
    toAmount,
  }
}

export function getResult(txInfo) {
  const { tx } = txInfo
  const operations = getOpperations(tx)

  if (!operations) return null

  const { logs } = txInfo
  const { events } = logs[0]
  const fromContract = events.find(({ type }) => type === "from_contract")?.attributes
  const swap = events.find(({ type }) => type === "swap")?.attributes

  const swaps = makeActionObjects(fromContract)
  const nativeSwaps = makeSwapObjects(swap)

  const transactions = []

  for (let operation of operations) {
    if (operation.hasOwnProperty("native_swap")) {
      transactions.push(getNativeSwapOperation(operation.native_swap, nativeSwaps))
      continue
    }

    transactions.push(getCustomSwapOperation(operation.t_f_m_swap, swaps))
  }

  const taxes = []
  const commisions = []
  const spreads = []

  for (let transaction of transactions) {
    if (transaction.tax) taxes.push(transaction.tax)
    if (transaction.commision) commisions.push(transaction.commision)
    if (transaction.spread) spreads.push(transaction.spread)
  }

  let fee = tx.value.fee.amount.map((value) => {
    return `${formatAmount(value.amount, value.denom) || "0"} ${tokensDictionary[value.denom]?.symbol || "N/D"}`
  })

  const firstOperation = transactions[0]
  const lastOperation = transactions[transactions.length - 1]

  const fromAmount = firstOperation.fromAmount
  const toAmount = lastOperation.toAmount

  return {
    taxes,
    commisions,
    spreads,
    fee,
    fromAmount,
    toAmount,
  }
}
