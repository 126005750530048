import React, { SetStateAction, useContext, useEffect } from "react";
import LoaderIcon from "../icons/loader";
import formatNumbers from "../../../helpers/formatNumbers";
import { useHistory, useLocation } from "react-router-dom";
import dexList from "../../../constants/dexList.json";
import TokenContext from "../../../layouts/TokenContext";
import ProSwapContext from "../context/proSwap";

const tableHeader = [
  "Name",
  "Markets",
  "Volume, 24h",
  "Liquidity",
  "Price",
  "24h",
];

interface Props {
  data: any[];
  loading: boolean;
  setSearchValue: React.Dispatch<SetStateAction<string>>;
  setOpenSearch: React.Dispatch<SetStateAction<boolean>>;
  assetsInfo: Object;
}

const SearchTable = ({
  data,
  loading,
  setSearchValue,
  setOpenSearch,
  assetsInfo,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { pairsInfo } = useContext(TokenContext);
  const { setActivePair } = useContext(ProSwapContext);

  return (
    <div className="mt-2 mb-1-5">
      {loading ? (
        <div className="flex justify-center items-center py-4">
          <div>Loading</div>

          <div className="ml-ch h-8">
            <LoaderIcon />
          </div>
        </div>
      ) : data.length > 0 ? (
        <div className="grid-search">
          <div className="hidden lg:grid grid-search col-span-full px-4">
            {tableHeader.map((item, index) => {
              return (
                <div
                  key={`item_${index}`}
                  className={`opacity-70 text-xs hidden px-1 md:grid
										${index === 1 && "text-left col-span-2"} 
										${
                      index === 0
                        ? "col-span-4"
                        : index === 6
                        ? "cols-span-2 text-right"
                        : "col-span-2 text-right"
                    }`}
                >
                  {item}
                </div>
              );
            })}
          </div>

          <div className="grid grid-cols-2 lg:hidden col-span-full px-4">
            <div className="opacity-70 text-xs">Name</div>
            <div className="opacity-70 text-xs text-right">Volume</div>
          </div>

          {data.map((item, index) => {
            return (
              <>
                <div
                  key={`item_${index}`}
                  className="hidden lg:grid col-span-full grid-search text-2sm py-2-5 px-4 items-center cursor-pointer hover:bg-black-80 transition-all"
                  onClick={() => {
                    if (history.location.pathname.includes("/analytics")) {
                      history.push(`/analytics/pairs/${item.contractAddr}`);
                      setSearchValue("");
                      setOpenSearch(false);
                    }
                    if (history.location.pathname.includes("/trade")) {
                      const params = new URLSearchParams();

                      params.set("token0", item.token0Address);
                      params.set("token1", item.token1Address);

                      setActivePair({
                        id: item.contractAddr,
                        token1: item.token0Address,
                        token2: item.token1Address,
                        symbol1: item.name.split('/')[0],
                        symbol2: item.name.split('/')[1],
                      });

                    //   history.push(location.pathname + "?" + params.toString());

                      setSearchValue("");
                      setOpenSearch(false);
                    }
                  }}
                >
                  <div className="col-span-4 px-1 flex items-center">
                    <div className="w-10 w-min-10 h-5 relative mr-2 flex items-center">
                      <div className="flex pair-wrapper p-1 absolute top-centered left-0">
                        <img
                          className="object-scale-down w-5 h-5 rounded-full"
                          src={item.firstIcon}
                          alt=""
                        />
                      </div>
                      <div className="flex pair-wrapper p-1 absolute top-centered -right-2">
                        <img
                          className="object-scale-down w-5 h-5 rounded-full"
                          src={item.secondIcon}
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="ml-2 uppercase">{item.name}</div>
                  </div>

                  <div
                    className={`col-span-2 px-1 flex items-center text-pairNumber ${
                      item.market === "Tfm" && "uppercase"
                    }`}
                  >
                    <img
                      className="object-scale-down w-5 h-5 rounded-full mr-1"
                      src={dexList[item.market.toLowerCase()]?.image}
                      alt=""
                    />
                    {item.market}
                  </div>

                  <div
                    className={`col-span-2 px-1 text-right ${
                      item.absoluteVolume24 >= 0 ? "text-action" : "text-red"
                    }`}
                  >
                    ${formatNumbers(item.absoluteVolume24.replace("-", ""))}
                  </div>

                  <div className="col-span-2 px-1 text-right">
                    ${formatNumbers(item.liquidity)}
                  </div>

                  <div className="col-span-2 px-1 text-right">
                    {assetsInfo[pairsInfo[item.contractAddr].token0Address]
                      ?.symbol === "UST" ||
                    assetsInfo[pairsInfo[item.contractAddr].token1Address]
                      ?.symbol === "UST"
                      ? `$${formatNumbers(item.priceInverted)}`
                      : `${formatNumbers(item.price)} ${
                          assetsInfo[pairsInfo[item.contractAddr].token1Address]
                            ?.symbol
                        }`}
                  </div>

                  <div className="col-span-2 px-1 text-right">
                    <div
                      className={`w-full ${
                        Number(item.diff24) >= 0 ? "text-action" : "text-red"
                      }`}
                    >
                      {Number(item.diff24).toFixed(2)} %
                    </div>
                  </div>
                </div>

                <div
                  key={`item_${index}`}
                  className="grid lg:hidden col-span-full grid-searchMobile text-sm py-2-5 px-4 items-center cursor-pointer hover:search-hover transition-all"
                  onClick={() => {
                    if (history.location.pathname.includes("/analytics")) {
                      history.push(`/analytics/pairs/${item.contractAddr}`);
                      setSearchValue("");
                      setOpenSearch(false);
                    }
                    if (history.location.pathname.includes("/trade")) {
                      const params = new URLSearchParams();

                      params.set("token0", item.token0Address);
                      params.set("token1", item.token1Address);

                      history.push(location.pathname + "?" + params.toString());

                      setSearchValue("");
                      setOpenSearch(false);
                    }
                  }}
                >
                  <div className="col-span-4 flex items-center">
                    <div className="w-10 w-min-10 h-5 relative mr-2 flex items-center">
                      <div className="flex pair-wrapper p-1 absolute top-centered left-0">
                        <img
                          className="object-scale-down w-5 h-5 rounded-full"
                          src={item.firstIcon}
                          alt=""
                        />
                      </div>
                      <div className="flex pair-wrapper p-1 absolute top-centered -right-2">
                        <img
                          className="object-scale-down w-5 h-5 rounded-full"
                          src={item.secondIcon}
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="ml-2 uppercase">{item.name}</div>
                  </div>

                  <div
                    className={`col-span-1 text-right ${
                      item.absoluteVolume24 >= 0 ? "text-action" : "text-red"
                    }`}
                  >
                    ${formatNumbers(item.absoluteVolume24.replace("-", ""))}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      ) : (
        <div className="text-xl text-glow py-8 text-center">
          No data found...
        </div>
      )}
    </div>
  );
};

export default SearchTable;
