import React, { useCallback } from 'react'

import StarIcon from "components/platform/icons/star";

import tokensDictionary from "constants/tokensDictionary.json"
import { getTokenIcon } from "helpers/token";
import TokenContext from "layouts/TokenContext";
import { useContext, useMemo } from "react";
import BigNumber from 'bignumber.js';
import { Link, useLocation } from 'react-router-dom';


const getUsdPair = (address, pairs) => {
  return pairs[address + "uusd"] || pairs["uusd" + address]
}

const MyTokenLine = ({ address, amount }) => {
  const location = useLocation()

  const { pairsInfo } = useContext(TokenContext)

  const formatedAmount = useMemo(() => {
    const decimals = tokensDictionary[address]?.decimals || 6
    return new BigNumber(amount).dividedBy(10 ** decimals).toFixed(decimals)
  }, [amount, address])

  const usdAmount = useMemo(() => {
    if (!Object.keys(pairsInfo).length)
      return

    return amount
  }, [amount, address, pairsInfo])

  const getURL = useCallback(() => {
    const params = new URLSearchParams(location.search)

    params.set("token0", address)

    return location.pathname + "?" + params.toString()
  }, [location, address])

  return (
    <Link to={getURL()}>
      <div className='grid-cols-max-fr text-sm gap-4 my-1-5 py-3'>
        {/* <div className='grid grid-cols-3 text-sm gap-4 my-1-5 py-3'> */}
        <div className='flex items-center'>
          {/*<div className='w-4 h-4'>*/}
          {/*  <StarIcon />*/}
          {/*</div>*/}
          <img className='ml-4 mr-2 w-5 h-5 rounded-full' src={getTokenIcon(address)} />
          <div>
            {tokensDictionary[address]?.symbol || "N/D"}
          </div>
        </div>
        <div className='place-self-end'>
          {formatedAmount}
        </div>
        {/* <div className='place-self-end flex items-center'>
        <div className='text-header-tab'>
          $
        </div>
        <div>
          {usdAmount}
        </div>
      </div> */}
      </div>
    </Link>
  );
}

export default MyTokenLine
