import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import Search from './search'
import Dropdown from '../Dropdown'
import Favorites from '../icons/favorites'

type TabListType = {
	label: string,
	href: string,
	regex?: RegExp
}[]

interface NavbarProps {
	tabList: TabListType
	withSearch?: boolean
	withPlatformAndFavorites?: boolean
	gridStyle?: string
	liquidity?:boolean
}

const Navbar = ({ tabList, withSearch = false, withPlatformAndFavorites = false, gridStyle = "", liquidity = false }: NavbarProps) => {
	const location = useLocation()
	const [selectedOption, setSelectedOption] = useState({ value: 'Terrafinder', label: 'Terra Finder', icon: "/images/terra.svg" })
	const [isProTrade, setIsProTrade] = useState(false)

	const options = [
		{ value: 'Terrafinder', label: 'Terra Finder', icon: "/images/terra.svg" },
	]

	useEffect(() => {
		setIsProTrade(!!location.pathname.match("/protrade"))
	}, [location])
	return (
		<div className={`flex navbar h-full md:h-14 bg-theme-black overflow-visible relative z-full shadow-inset-bottom ${(location.hash === "#Liquidity" && !liquidity)? `hidden` : 'flex'}`}>
			<div className={`md:px-12 w-full ${gridStyle}`}>
				<div
					className={`col-span-full flex flex-wrap md:flex-nowrap items-center p-0 md:py-2 ${(!withSearch && !withPlatformAndFavorites) ? "justify-center" : "justify-between"}`}
				>
					<div className="flex justify-center items-center w-full md:w-auto">
						{tabList && tabList.map((item) => (
							<Link
								className="cursor-pointer text-base hover:bg-black-80 rounded-base transition-all duration-300"
								key={item.href}
								to={item.href + location.search}
							>
								<div
									style={{ whiteSpace: 'nowrap' }}
									className={`px-3 py-2 ${(location.pathname.match(item.regex) || (location.pathname+location.hash).match(item.regex) )? "active color-blue" : "text-white"}`}
								>
									{item.label}
								</div>
							</Link>
						))}
					</div>

					{(withSearch || withPlatformAndFavorites) && (
						<div
							className={`relative shadow-inset-top md:noBorder w-max-6xl lg:ml-2 w-full p-4 md:p-0 flex ${!withPlatformAndFavorites ? 'md:justify-end' : 'md:justify-between'}`}
						>
							<Search />
							<div className="hover:opacity-70 h-10 transition-all duration-300 cursor-pointer">
								{/*<Favorites width="40" height="40" />*/}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default Navbar
