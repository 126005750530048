import React from "react";

const LogoIcon = ({ className = '', width = "55", height = "66" }) => {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 940 940" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.5" d="M36.5092 721.851C46.8935 680.948 82.8697 630.126 139.756 575.998C196.643 521.87 271.806 466.943 355.464 418.364C439.122 369.785 527.403 329.803 608.817 303.622C690.231 277.441 761.009 266.273 811.941 271.57L722.786 340.872C684.905 336.932 632.262 345.239 571.709 364.711C511.157 384.184 445.497 413.92 383.275 450.052C321.053 486.183 265.149 527.036 222.839 567.294C180.529 607.553 153.771 645.352 146.047 675.775L36.5092 721.851Z" fill="#6BF491" />
      <path d="M579.882 814.542C734.775 749.992 809.077 574.653 745.841 422.91C707.8 331.627 628.652 269.678 537.901 248.855C525.817 256.753 499.684 274.126 484.712 286.258C468.401 299.476 458.471 308.945 445.995 320.842C440.704 325.887 434.955 331.37 428.067 337.76C418.703 346.447 411.137 354.359 403.815 362.016C391.354 375.046 379.598 387.339 360.886 401.462C335.473 420.643 307.409 433.79 287.761 437.515C274.108 440.104 244.345 437.755 235.232 415.889C229.289 401.629 226.278 391.725 232.853 370.017C236.412 358.263 245.736 340.108 249.072 333.78C245.731 337.188 242.477 340.666 239.313 344.212L232.776 351.782C163.355 434.888 141.076 551.448 184.923 656.664C248.16 808.407 424.989 879.091 579.882 814.542Z" fill="url(#paint0_linear_15774_199630)" />
      <path d="M162.434 550.168C163.156 585.828 170.424 621.867 184.926 656.665C248.163 808.407 424.991 879.091 579.884 814.542C734.777 749.993 809.08 574.653 745.843 422.911C734.796 396.402 720.282 372.367 703.024 351.095C693.927 350.776 682.128 352.804 667.426 359.876C652.393 367.107 636.71 376.473 620.66 387.044C588.888 433.873 532.374 482.632 478.472 513.887C411.1 552.952 347.359 574.28 278.619 577.435C235.001 579.436 193.157 569.626 168.73 554.43C166.578 553.092 164.476 551.669 162.434 550.168Z" fill="url(#paint1_linear_15774_199630)" />
      <path d="M162.434 550.17C163.156 585.829 170.425 621.867 184.926 656.664C188.149 664.397 191.667 671.92 195.462 679.226C314.97 707.385 484.408 566.104 542.515 503.928C556.851 488.587 572.644 468.007 589.126 446.531C623.295 402.005 660.421 353.627 693.647 340.104C679.558 324.4 663.901 310.382 647.012 298.183C648.411 317.171 646.173 338.6 636.22 360.007C611.029 414.186 542.883 476.535 478.468 513.886C411.097 552.952 347.356 574.279 278.615 577.434C234.997 579.436 193.153 569.625 168.726 554.43C166.575 553.092 164.475 551.67 162.434 550.17Z" fill="url(#paint2_linear_15774_199630)" />
      <path d="M647.012 298.183C671.478 315.855 693.357 337.344 711.632 362.245C671.25 365.449 617.187 421.251 570.816 469.114C553.672 486.809 537.58 503.419 523.619 515.885C458.837 573.729 311.807 668.354 217.709 656.094C204.545 654.379 192.022 650.664 180.526 645.474C168.947 614.173 163.079 582.02 162.434 550.17C164.475 551.67 166.575 553.092 168.726 554.43C193.153 569.625 234.997 579.436 278.615 577.434C347.356 574.279 411.097 552.952 478.468 513.886C542.883 476.535 611.029 414.186 636.219 360.007C646.173 338.6 648.41 317.171 647.012 298.183Z" fill="url(#paint3_linear_15774_199630)" />
      <path d="M748.816 430.329C747.86 427.85 746.869 425.377 745.841 422.911C741.215 411.81 735.98 401.142 730.191 390.93C694.765 382.029 659.573 410.56 634.056 432.308L612.345 456.36L562.064 500.984C559.129 504.131 556.185 507.304 553.212 510.508C544.468 519.933 535.473 529.628 525.719 539.746C515.753 550.083 507.051 559.261 498.887 567.871C477.056 590.896 459.07 609.864 431.042 636.109C369.708 693.541 320.347 719.524 274.031 722.085C247.505 723.553 226.088 714.685 209.355 703.14C238.484 748.204 278.879 783.363 325.327 806.534C363.959 803.559 397.866 784.402 419.419 766.231C464.102 728.558 482.595 700.116 506.895 662.743L506.896 662.741C513.592 652.443 520.728 641.468 528.974 629.434C539.943 613.428 549.536 596.85 559.078 580.359C574.944 552.939 590.67 525.762 612.345 501.865C638.77 472.731 655.894 453.861 679.126 439.927C699.398 427.769 722.226 420.365 748.816 430.329Z" fill="url(#paint4_linear_15774_199630)" />
      <path d="M731.98 394.127C722.974 390.994 714.63 391.387 706.72 393.494C687.468 398.624 682.213 402.53 670.357 411.342L670.352 411.346C667.715 413.306 664.751 415.509 661.234 418.022C636.567 435.647 622.15 449.769 603.799 467.744L603.798 467.745C598.341 473.09 592.537 478.775 586.012 484.995C565.619 504.432 551.132 520.231 536.23 536.483L536.229 536.484C523.801 550.037 511.085 563.904 494.415 580.459C483.062 591.734 473.246 602.125 463.775 612.152C438.197 639.229 415.135 663.643 371.104 695.597C368.618 697.402 366.022 699.329 363.321 701.335C337.768 720.306 302.791 746.274 262.01 741.356C250.449 739.961 239.844 736.368 230.674 732.033C223.038 722.92 215.914 713.282 209.359 703.143C226.092 714.686 247.508 723.553 274.032 722.085C320.347 719.524 369.709 693.541 431.043 636.109C459.07 609.864 477.056 590.896 498.887 567.871C507.051 559.261 515.753 550.083 525.719 539.746C535.473 529.628 544.469 519.933 553.213 510.508C575.547 486.436 596.242 464.13 623.754 441.072C626.046 439.151 628.451 437.095 630.961 434.951L630.963 434.949C656.685 412.966 693.313 381.662 730.194 390.931C730.795 391.991 731.39 393.057 731.98 394.127Z" fill="url(#paint5_linear_15774_199630)" />
      <path d="M452.605 838.327C490.909 825.606 520.733 801.204 544.061 769.665C583.06 716.939 590.643 689.555 604.979 637.781C608.979 623.334 613.505 606.989 619.386 587.665C636.206 532.396 678.665 445.652 735.056 458.206C745.623 460.558 754.687 467.339 762.29 475.988C790.928 613.512 716.968 757.415 579.886 814.542C538.167 831.928 494.858 839.503 452.605 838.327Z" fill="url(#paint6_linear_15774_199630)" />
      <path d="M452.602 838.327C490.906 825.606 520.729 801.204 544.057 769.665C583.056 716.939 590.639 689.554 604.975 637.781C608.975 623.335 613.501 606.989 619.382 587.664C636.202 532.396 678.661 445.652 735.052 458.206C745.621 460.559 754.686 467.341 762.289 475.992C764.721 487.672 766.413 499.399 767.39 511.114C759.152 500.922 748.038 492.623 733.314 493.279C713.864 494.146 696.629 505.427 685.111 521.124C666.247 546.83 653.665 575.11 639.792 606.293C630.843 626.407 621.358 647.728 609.302 670.345C606.532 675.543 604.067 680.187 601.776 684.506L601.767 684.522C591.836 703.239 585.143 715.853 570.775 741.008C556.447 766.091 537.319 791.246 514.128 809.677C498.025 822.475 482.242 831.623 465.801 838.409C461.392 838.477 456.991 838.449 452.602 838.327Z" fill="url(#paint7_linear_15774_199630)" />
      <path d="M452.605 838.327C490.909 825.606 520.733 801.204 544.061 769.665C583.06 716.938 590.642 689.555 604.979 637.781C608.979 623.335 613.505 606.989 619.386 587.664C636.206 532.396 678.665 445.652 735.056 458.206C745.624 460.558 754.689 467.34 762.291 475.99C763.003 479.409 763.652 482.832 764.238 486.257C755.638 474.343 743.037 461.477 727.188 461.711C704.582 462.044 685.852 476.96 673.366 491.899C638.926 533.107 625.923 580.108 608.894 643.214C600.318 674.997 590.581 700.964 572.894 731.929C558.566 757.013 534.216 792.419 505.128 813.198C491.635 822.836 476.728 831.83 459.539 838.441C457.225 838.429 454.914 838.391 452.605 838.327Z" fill="#5C4C18" />
      <path d="M633.953 289.271C629.712 331.384 615.722 373.378 550.178 438.922C516.706 472.393 450.757 515.399 356.435 540.892C299.252 556.346 234.536 550.226 204.755 540.892C192.714 537.118 174.772 527.054 163.777 515.588C174.278 408.603 243.051 309.97 350.883 265.033C447.702 224.685 553.092 237.175 633.953 289.271Z" fill="url(#paint8_linear_15774_199630)" />
      <path d="M446.017 498.301C562.668 451.394 635.667 359.74 609.065 293.585C604.816 283.018 598.282 273.74 589.828 265.802C516.784 234.431 430.908 231.685 350.886 265.033C269.129 299.104 209.824 364.042 181.348 440.083C181.886 448.131 183.621 455.954 186.635 463.449C213.237 529.604 329.366 545.208 446.017 498.301Z" fill="url(#paint9_linear_15774_199630)" />
      <path d="M433.653 466.006C535.946 424.873 599.959 344.5 576.632 286.488C570.518 271.284 559.012 259.125 543.518 250.199C481.871 234.819 414.527 238.511 350.885 265.033C286.115 292.025 235.437 338.389 203.015 394.3C200.151 408.665 201.035 422.602 206.199 435.444C229.526 493.456 331.361 507.139 433.653 466.006Z" fill="url(#paint10_linear_15774_199630)" />
      <ellipse cx="385.515" cy="348.015" rx="160.804" ry="84.6435" transform="rotate(-21.9057 385.515 348.015)" fill="#6BF491" />
      <ellipse cx="389.559" cy="338.893" rx="123.583" ry="65.0516" transform="rotate(-21.9057 389.559 338.893)" fill="#7AFF9F" />
      <ellipse cx="383.546" cy="329.357" rx="92.2816" ry="48.575" transform="rotate(-21.9057 383.546 329.357)" fill="#7AFF9F" />
      <path d="M368.754 216.825C378.078 216.758 385.761 218.873 390.632 222.847C395.504 226.821 397.298 232.437 395.741 238.84C394.184 245.243 389.361 252.082 382.008 258.316C374.654 264.551 365.171 269.839 355.004 273.376C344.836 276.912 334.54 278.504 325.683 277.909C316.826 277.313 309.894 274.563 305.94 270.077C301.987 265.591 301.23 259.614 303.784 253.057C306.338 246.5 312.063 239.722 320.088 233.754L325.834 236.444C319.388 241.238 314.789 246.682 312.738 251.949C310.686 257.216 311.295 262.017 314.47 265.621C317.646 269.224 323.214 271.433 330.329 271.911C337.443 272.39 345.713 271.111 353.88 268.27C362.047 265.429 369.664 261.182 375.571 256.174C381.478 251.166 385.352 245.672 386.602 240.529C387.853 235.386 386.412 230.875 382.499 227.683C378.586 224.491 372.415 222.792 364.925 222.846L368.754 216.825Z" fill="#303736" />
      <path d="M391.825 274.274C401.149 274.207 408.831 276.322 413.703 280.296C418.574 284.27 420.368 289.886 418.811 296.289C417.254 302.692 412.432 309.531 405.078 315.765C397.724 321.999 388.242 327.288 378.074 330.824C367.906 334.361 357.61 335.953 348.754 335.358C339.897 334.762 332.964 332.012 329.011 327.526C325.057 323.04 324.3 317.063 326.854 310.506C329.408 303.949 335.133 297.171 343.159 291.202L348.905 293.893C342.459 298.687 337.86 304.131 335.808 309.398C333.757 314.665 334.365 319.466 337.54 323.07C340.716 326.673 346.285 328.882 353.399 329.36C360.513 329.839 368.783 328.56 376.95 325.719C385.118 322.878 392.734 318.63 398.641 313.623C404.548 308.615 408.422 303.121 409.672 297.978C410.923 292.835 409.482 288.324 405.569 285.132C401.656 281.94 395.485 280.241 387.996 280.295L391.825 274.274Z" fill="#303736" />
      <path d="M386.055 259.912C395.379 259.845 403.062 261.96 407.933 265.934C412.805 269.908 414.599 275.524 413.042 281.927C411.485 288.33 406.662 295.169 399.308 301.403C391.955 307.638 382.472 312.926 372.304 316.463C362.137 319.999 351.841 321.591 342.984 320.996C334.127 320.4 327.194 317.65 323.241 313.164C319.288 308.678 318.531 302.701 321.085 296.144C323.639 289.587 329.364 282.809 337.389 276.841L343.135 279.531C336.689 284.325 332.09 289.77 330.039 295.037C327.987 300.304 328.595 305.104 331.771 308.708C334.946 312.311 340.515 314.52 347.629 314.999C354.744 315.477 363.014 314.198 371.181 311.357C379.348 308.516 386.965 304.269 392.872 299.261C398.779 294.253 402.652 288.76 403.903 283.616C405.154 278.473 403.713 273.962 399.8 270.77C395.886 267.578 389.716 265.879 382.226 265.933L386.055 259.912Z" fill="#303736" />
      <path d="M380.29 245.549C389.613 245.482 397.296 247.597 402.167 251.571C407.039 255.545 408.833 261.161 407.276 267.564C405.719 273.967 400.897 280.806 393.543 287.041C386.189 293.275 376.706 298.563 366.539 302.1C356.371 305.637 346.075 307.229 337.218 306.633C328.362 306.038 321.429 303.288 317.475 298.801C313.522 294.315 312.765 288.339 315.319 281.782C317.873 275.224 323.598 268.446 331.624 262.478L337.37 265.168C330.923 269.962 326.324 275.407 324.273 280.674C322.222 285.941 322.83 290.741 326.005 294.345C329.181 297.948 334.749 300.158 341.864 300.636C348.978 301.114 357.248 299.835 365.415 296.995C373.582 294.154 381.199 289.906 387.106 284.898C393.013 279.89 396.887 274.397 398.137 269.254C399.388 264.11 397.947 259.599 394.034 256.407C390.121 253.215 383.95 251.517 376.461 251.57L380.29 245.549Z" fill="#303736" />
      <mask id="mask0_15774_199630" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="331" y="291" width="80" height="71">
        <path d="M392.808 361.697L410.988 291.859L331.383 323.817L392.808 361.697Z" fill="#5CDA7D" />
      </mask>
      <g mask="url(#mask0_15774_199630)">
        <path d="M392.808 361.697L410.988 291.859L331.383 323.817L392.808 361.697Z" fill="#5CDA7D" />
        <path d="M395.388 363.993L356.84 334.44L364.021 331.557L395.388 363.993Z" fill="#9AFDB4" />
        <path d="M392.522 365.147C392.312 350.777 392.065 321.636 392.753 320.025L405.962 311.387L412.011 322.3L392.522 365.147Z" fill="#339966" />
      </g>
      <path d="M374.52 231.187C383.844 231.12 391.526 233.235 396.398 237.209C401.269 241.183 403.063 246.799 401.506 253.202C399.949 259.605 395.127 266.444 387.773 272.678C380.419 278.913 370.937 284.201 360.769 287.738C350.602 291.275 340.306 292.866 331.449 292.271C322.592 291.675 315.659 288.925 311.706 284.439C307.753 279.953 306.995 273.976 309.549 267.419C312.103 260.862 317.829 254.084 325.854 248.116L331.6 250.806C325.154 255.6 320.555 261.045 318.503 266.312C316.452 271.579 317.06 276.379 320.236 279.983C323.411 283.586 328.98 285.795 336.094 286.274C343.208 286.752 351.479 285.473 359.646 282.632C367.813 279.791 375.43 275.544 381.337 270.536C387.244 265.528 391.117 260.035 392.368 254.891C393.618 249.748 392.177 245.237 388.264 242.045C384.351 238.853 378.18 237.154 370.691 237.208L374.52 231.187Z" fill="#303736" />
      <mask id="mask1_15774_199630" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="295" y="190" width="120" height="144">
        <path d="M369.992 190.911L295.307 220.894L340.23 332.793C340.23 332.793 356.612 336.212 381.028 326.409C405.445 316.607 414.915 302.81 414.915 302.81L369.992 190.911Z" fill="#5CDA7D" />
      </mask>
      <g mask="url(#mask1_15774_199630)">
        <path d="M369.992 190.911L295.307 220.894L340.23 332.793C340.23 332.793 356.612 336.212 381.028 326.409C405.445 316.607 414.915 302.81 414.915 302.81L369.992 190.911Z" fill="#5CDA7D" />
        <path d="M379.441 187L344.971 200.838L401.125 340.713C401.125 340.713 411.411 349.077 422.68 344.553C433.949 340.029 435.595 326.874 435.595 326.874L379.441 187Z" fill="#339966" />
        <path d="M316.242 212.37L307.625 215.829L363.779 355.704C363.779 355.704 369.59 365.865 372.407 364.734C375.224 363.603 372.396 352.244 372.396 352.244L316.242 212.37Z" fill="#9AFDB4" />
      </g>
      <mask id="mask2_15774_199630" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="197" y="88" width="234" height="147">
        <path d="M430.732 151.229L405.356 88.0196L197.37 171.518L222.746 234.727L283.151 234.797L387.144 193.048L430.732 151.229Z" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask2_15774_199630)">
        <path d="M430.732 151.229L405.356 88.0196L197.37 171.518L222.746 234.727L283.151 234.797L387.144 193.048L430.732 151.229Z" fill="#C4C4C4" />
        <path d="M275.035 128.953L167.923 171.954L209.437 275.364L316.55 232.363L275.035 128.953Z" fill="#5CDA7D" />
        <path d="M228.199 144.259L221.669 146.881L296.626 333.593C296.626 333.593 304.672 347.04 306.55 346.286C308.428 345.532 303.156 330.971 303.156 330.971L228.199 144.259Z" fill="#9AFDB4" />
        <path d="M214.837 146.288L213.183 146.952L288.14 333.664C288.14 333.664 293.313 348.265 295.191 347.511C297.069 346.757 289.794 333 289.794 333L214.837 146.288Z" fill="#9AFDB4" />
        <path d="M358.355 112.18L251.243 155.181L292.758 258.591L399.87 215.59L358.355 112.18Z" fill="#339966" />
        <rect width="111.432" height="111.432" transform="matrix(-0.928009 0.372558 0.372558 0.928009 456.586 66.0732)" fill="#3B5B4F" />
        <path d="M381.953 179.261L416.059 103.863L435.086 151.259L391.171 195.573L284.053 236.908L293.758 231.345L381.953 179.261Z" fill="#303736" />
      </g>
      <mask id="mask3_15774_199630" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="197" y="87" width="209" height="85">
        <path d="M405.408 88.0343L345.003 87.9648L241.01 129.714L197.422 171.532L257.827 171.602L361.82 129.853L405.408 88.0343Z" fill="#5CDA7D" />
      </mask>
      <g mask="url(#mask3_15774_199630)">
        <path d="M405.408 88.0343L345.003 87.9648L241.01 129.714L197.422 171.532L257.827 171.602L361.82 129.853L405.408 88.0343Z" fill="#5CDA7D" />
        <rect width="172.985" height="15.4766" transform="matrix(-0.731489 0.681853 0.681853 0.731489 349.387 68.9433)" fill="#9AFDB4" />
        <rect width="172.985" height="4.41924" transform="matrix(-0.731489 0.681853 0.681853 0.731489 324.363 78.9911)" fill="#9AFDB4" />
      </g>
      <path d="M395.253 228.976C397.853 235.452 395.504 243.606 388.723 251.645C381.942 259.684 371.285 266.95 359.095 271.843C346.906 276.737 334.183 278.858 323.726 277.739C313.268 276.62 305.933 272.354 303.333 265.878L311.917 262.432C314.031 267.698 319.996 271.168 328.501 272.078C337.005 272.988 347.352 271.263 357.265 267.283C367.178 263.304 375.845 257.395 381.359 250.857C386.873 244.32 388.784 237.688 386.67 232.422L395.253 228.976Z" fill="#303736" />
      <path d="M418.323 286.425C420.923 292.901 418.574 301.055 411.793 309.094C405.012 317.133 394.355 324.399 382.166 329.292C369.976 334.186 357.254 336.306 346.796 335.188C336.339 334.069 329.003 329.803 326.404 323.327L334.987 319.881C337.101 325.147 343.067 328.617 351.571 329.527C360.075 330.436 370.422 328.712 380.335 324.732C390.248 320.753 398.915 314.844 404.429 308.306C409.944 301.769 411.854 295.137 409.74 289.871L418.323 286.425Z" fill="#303736" />
      <path d="M412.554 272.063C415.154 278.539 412.805 286.693 406.024 294.732C399.243 302.771 388.585 310.037 376.396 314.93C364.207 319.824 351.484 321.945 341.027 320.826C330.569 319.707 323.234 315.441 320.634 308.965L329.218 305.519C331.332 310.786 337.297 314.255 345.802 315.165C354.306 316.075 364.653 314.35 374.565 310.37C384.478 306.391 393.145 300.482 398.66 293.945C404.174 287.407 406.085 280.775 403.97 275.509L412.554 272.063Z" fill="#303736" />
      <path d="M406.788 257.701C409.388 264.176 407.039 272.33 400.258 280.37C393.477 288.409 382.82 295.674 370.63 300.568C358.441 305.461 345.718 307.582 335.261 306.463C324.804 305.345 317.468 301.078 314.869 294.603L323.452 291.157C325.566 296.423 331.532 299.892 340.036 300.802C348.54 301.712 358.887 299.987 368.8 296.008C378.713 292.028 387.38 286.12 392.894 279.582C398.409 273.044 400.319 266.413 398.205 261.146L406.788 257.701Z" fill="#303736" />
      <path d="M401.019 243.338C403.618 249.814 401.269 257.968 394.489 266.007C387.708 274.047 377.05 281.312 364.861 286.206C352.672 291.099 339.949 293.22 329.491 292.101C319.034 290.982 311.699 286.716 309.099 280.24L317.682 276.794C319.797 282.061 325.762 285.53 334.266 286.44C342.771 287.35 353.117 285.625 363.03 281.646C372.943 277.666 381.61 271.757 387.125 265.22C392.639 258.682 394.549 252.05 392.435 246.784L401.019 243.338Z" fill="#303736" />
      <path d="M162.637 556.521C163.567 578.645 167.028 600.879 173.183 622.836C173.202 622.809 173.221 622.783 173.24 622.757C169.717 605.071 168.271 594.954 166.364 581.608C165.368 574.639 164.246 566.789 162.637 556.521Z" fill="url(#paint11_linear_15774_199630)" />
      <path d="M293.679 788.252C370.013 766.06 454.453 730.519 536.784 685.784C624.559 638.091 705.399 582.414 768.266 526.44C767.563 503.337 764.107 480.094 757.712 457.15C743.054 478.205 722.606 500.917 697.063 524.328C649.41 568.003 586.61 611.743 518.134 648.949C449.659 686.156 379.221 714.812 317.447 730.594C289.18 737.816 263.429 742.163 240.912 743.606C256.776 760.619 274.515 775.56 293.679 788.252Z" fill="url(#paint12_linear_15774_199630)" />
      <path d="M693.658 340.111C681.968 327.08 669.199 315.21 655.543 304.577C666.18 313.454 675.622 324.072 687.644 337.591C688.43 338.475 689.227 339.371 690.035 340.28C691.253 340.215 692.46 340.158 693.658 340.111Z" fill="url(#paint13_linear_15774_199630)" />
      <path d="M643.261 295.514C642.491 294.976 641.719 294.441 640.944 293.911C640.892 293.925 640.839 293.94 640.786 293.955C641.624 294.469 642.449 294.988 643.261 295.514Z" fill="url(#paint14_linear_15774_199630)" />
      <path d="M240.91 743.606C263.427 742.163 289.178 737.816 317.444 730.594C378.178 715.078 447.285 687.118 514.668 650.823C511.992 654.905 509.413 658.871 506.897 662.741L506.896 662.742C492.687 684.597 480.463 703.397 463.826 722.776C405.466 750.277 347.553 772.589 293.677 788.252C274.513 775.56 256.774 760.618 240.91 743.606Z" fill="url(#paint15_linear_15774_199630)" />
      <path opacity="0.5" d="M811.928 271.72C853.276 276.018 880.139 291.024 890.179 315.431C900.219 339.839 893.132 372.908 869.534 411.761C845.937 450.613 806.544 494.072 754.784 538.354C703.025 582.637 640.468 626.4 572.558 665.836C504.647 705.272 433.442 739.185 365.14 764.624C296.837 790.062 233.508 806.255 180.663 811.793C127.818 817.331 87.0591 812.047 61.9344 796.399C36.8098 780.751 28.0805 755.215 36.5063 722.013L146.045 675.934C139.779 700.629 146.271 719.621 164.958 731.26C183.645 742.898 213.96 746.828 253.264 742.709C292.568 738.59 339.67 726.547 390.471 707.626C441.272 688.706 494.232 663.483 544.741 634.152C595.251 604.82 641.778 572.271 680.275 539.335C718.772 506.4 748.071 474.076 765.622 445.179C783.173 416.282 788.444 391.686 780.977 373.533C773.509 355.38 753.53 344.219 722.777 341.022L811.928 271.72Z" fill="#6BF491" />
      <defs>
        <linearGradient id="paint0_linear_15774_199630" x1="389.797" y1="310.19" x2="314.784" y2="588.046" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A3EE75" />
          <stop offset="1" stop-color="#75CE79" />
        </linearGradient>
        <linearGradient id="paint1_linear_15774_199630" x1="606.064" y1="361.659" x2="358.109" y2="656.679" gradientUnits="userSpaceOnUse">
          <stop stop-color="#DDDD59" />
          <stop offset="1" stop-color="#7D7532" />
        </linearGradient>
        <linearGradient id="paint2_linear_15774_199630" x1="264.65" y1="655.608" x2="527.222" y2="533.244" gradientUnits="userSpaceOnUse">
          <stop stop-color="#807E32" />
          <stop offset="1" stop-color="#A09B41" stop-opacity="0" />
          <stop offset="1" stop-color="#A09B41" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint3_linear_15774_199630" x1="96.883" y1="545.072" x2="636.089" y2="359.959" gradientUnits="userSpaceOnUse">
          <stop stop-color="#7B7632" />
          <stop offset="1" stop-color="#CAEF5F" />
        </linearGradient>
        <linearGradient id="paint4_linear_15774_199630" x1="761.912" y1="402.148" x2="335.998" y2="806.385" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D2B24C" />
          <stop offset="1" stop-color="#504B30" />
        </linearGradient>
        <linearGradient id="paint5_linear_15774_199630" x1="747.957" y1="369.303" x2="396.161" y2="772.084" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C6A945" />
          <stop offset="1" stop-color="#4B472C" />
        </linearGradient>
        <linearGradient id="paint6_linear_15774_199630" x1="691.307" y1="558.339" x2="554.667" y2="875.78" gradientUnits="userSpaceOnUse">
          <stop stop-color="#7C671C" />
          <stop offset="1" stop-color="#60501A" />
        </linearGradient>
        <linearGradient id="paint7_linear_15774_199630" x1="567.592" y1="525.116" x2="829.338" y2="794.852" gradientUnits="userSpaceOnUse">
          <stop stop-color="#60511B" />
          <stop offset="1" stop-color="#726420" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint8_linear_15774_199630" x1="214.705" y1="440.929" x2="479.304" y2="447.016" gradientUnits="userSpaceOnUse">
          <stop stop-color="#51D173" />
          <stop offset="1" stop-color="#43DA6C" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint9_linear_15774_199630" x1="349.683" y1="258.733" x2="547.622" y2="428.725" gradientUnits="userSpaceOnUse">
          <stop stop-color="#5CDA7D" />
          <stop offset="1" stop-color="#7DF57A" />
        </linearGradient>
        <linearGradient id="paint10_linear_15774_199630" x1="411.236" y1="241.355" x2="203.472" y2="326.755" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6BF391" />
          <stop offset="1" stop-color="#5EDC7D" />
        </linearGradient>
        <linearGradient id="paint11_linear_15774_199630" x1="389.803" y1="310.189" x2="314.791" y2="588.045" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A3EE75" />
          <stop offset="1" stop-color="#75CE79" />
        </linearGradient>
        <linearGradient id="paint12_linear_15774_199630" x1="389.803" y1="310.189" x2="314.791" y2="588.045" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A3EE75" />
          <stop offset="1" stop-color="#75CE79" />
        </linearGradient>
        <linearGradient id="paint13_linear_15774_199630" x1="389.803" y1="310.189" x2="314.791" y2="588.045" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A3EE75" />
          <stop offset="1" stop-color="#75CE79" />
        </linearGradient>
        <linearGradient id="paint14_linear_15774_199630" x1="389.803" y1="310.189" x2="314.791" y2="588.045" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A3EE75" />
          <stop offset="1" stop-color="#75CE79" />
        </linearGradient>
        <linearGradient id="paint15_linear_15774_199630" x1="761.912" y1="402.148" x2="335.998" y2="806.385" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D2B24C" />
          <stop offset="1" stop-color="#504B30" />
        </linearGradient>
      </defs>
    </svg >

  )
}

export default LogoIcon
