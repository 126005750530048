import React from "react";
import Button from "components/platform/button"
import ErrorWarning from "./icons/errorWarning"

const ErrorMessage = ({ error, onClose, additionalMessage = true}) => {
    return (
        <>
            <div className="flex justify-center flex-col items-center">
                <div className="w-20 h-20">
                    <ErrorWarning />
                </div>
                <div className="text-center error-message">The transaction cannot succeed due to error: {error ? error.message || error : "undefined"}. {additionalMessage && ' This is probably an issue with one of the tokens you are swapping.'}</div>
            </div>
            <Button onClick={onClose} className="w-full mt-8 flex justify-center items-center">
                Dismiss
            </Button>
        </>
    );
}

export default ErrorMessage
