import axios from "axios";
import { TokenInfo, PairInfo } from "components/platform/swapForm/PairsList";
import { datamigration } from "googleapis/build/src/apis/datamigration";
import { pair } from "ramda";
import React, { MutableRefObject, ReactNode, useEffect, useRef, useState } from "react";

const TokenContext = React.createContext<
    {
        currentPair: any
        setCurrentPair: React.Dispatch<any>,
        assetsInfo: { [key: string]: TokenInfo },
        assetsInfoLoading: boolean,
        pairsInfo: { [key: string]: PairInfo },
    }
>({
    currentPair: null,
    setCurrentPair: (arg) => { },
    assetsInfo: {},
    assetsInfoLoading: true,
    pairsInfo: {}
});

interface Props {
    children: ReactNode;
}

const TokenContextProvider = ({ children }: Props) => {
    const [currentPair, setCurrentPair] = useState(null)
    const [assetsInfo, setAssetsInfo] = useState<{ [key: string]: TokenInfo }>({});
    const [pairsInfo, setPairsInfo] = useState<{ [key: string]: PairInfo }>({})
    const [assetsInfoLoading, setAssetsInfoLoading] = useState(true);

    const getTokens = async () => {
        try {
            const { data } = await axios.post("https://tfm.sspqf.org/graphql", {
                query: ` 
            query MyQuery {
                pair(limit: 2000) {
                  content {
                    token0Address
                    token1Address
                    liquidityTokenAddress
                    contractAddr
                    market
                  }
                }
                token(limit: 2000) {
                  contractAddr
                  decimals
                  icon
                  market
                  name
                  symbol
                  totalSupply
                }
              }
            `})

            const tokens: { [key: string]: TokenInfo } = {}
            const pairs: { [key: string]: PairInfo } = {}

            for (let token of data.data.token) {
                tokens[token.contractAddr] = { ...token, divider: Math.pow(10, token.decimals || 6) }
            }
            for (let pair of data.data.pair.content) {
                pairs[pair.token0Address + pair.token1Address] = pair
                pairs[pair.token1Address + pair.token0Address] = pairs[pair.token0Address + pair.token1Address]
                pairs[pair.contractAddr] = pairs[pair.token0Address + pair.token1Address]
            }

            setAssetsInfo(tokens)
            setPairsInfo(pairs)
            setAssetsInfoLoading(false)
        } catch (e) {
            console.warn(e.message)
        }
    }

    useEffect(() => {
        getTokens()
    }, [])



    return (
        <TokenContext.Provider
            value={{
                currentPair,
                setCurrentPair,
                assetsInfo,
                assetsInfoLoading,
                pairsInfo
            }}
        >
            {children}
        </TokenContext.Provider>
    );
};


export default TokenContext;

export { TokenContextProvider };
