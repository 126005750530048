import React, { useEffect } from "react"
import routes from "routes"
import { ContractProvider, useContractState } from "hooks/useContract"
import { ThemeProvider } from "styled-components"
import variables from "styles/_variables.scss"
import { useAddress } from "hooks"
import smoothscroll from 'smoothscroll-polyfill';

const App = () => {
  useEffect(() => {
    smoothscroll.polyfill();
  }, [])

  const address = useAddress()
  const contract = useContractState(address)
  return (
    <ThemeProvider theme={variables}>
      <ContractProvider value={contract}>{routes()}</ContractProvider>
    </ThemeProvider>
  )
}

export default App
