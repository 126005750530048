import React, { ReactNode, useState } from 'react'

const ProSwapContext = React.createContext({
    activePair: {
        id: "",
        token1: "",
        token2: "",
        symbol1: "",
        symbol2: "",
    },
    pairInfo: null,
    pairData: null,
    isLoading: false,
    tokens: { token1: "", token2: "", symbol1: "", symbol2: "" },
    setTokens: (arg: any) => { },
    setIsLoading: (arg: any) => { },
    setActivePair: (arg: any) => { },
    setPairInfo: (arg: any) => { },
    setPairData: (arg: any) => { },
    tokenIcons: { iconFirstToken: "", iconSecondToken: "" },
    setTokenIcons: (arg: any) => { },
    tokenPrice: { firstPrice: "0", secondPrice: "0" },
    setTokenPrice: (arg: any) => { },
})

interface Props {
    children: ReactNode;
}

interface ActivePair {
    id: string | null;
    token1: string;
    token2: string;
    symbol1: string;
    symbol2: string;
}

const ProSwapContextProvider = ({ children }: Props) => {
    const [activePair, setActivePair] = useState<ActivePair>({
        id: "",
        token1: "",
        token2: "",
        symbol1: "",
        symbol2: "",
    })

    const [pairInfo, setPairInfo] = useState(null)
    const [pairData, setPairData] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(true)
    const [tokens, setTokens] = useState<any>({})
    const [tokenIcons, setTokenIcons] = useState(null);
    const [tokenPrice, setTokenPrice] = useState(null);

    return (
        <ProSwapContext.Provider
            value={{
                activePair,
                pairInfo,
                pairData,
                tokens,
                setIsLoading,
                isLoading,
                setTokens,
                setActivePair,
                setPairData,
                setPairInfo,
                tokenIcons,
                setTokenIcons,
                tokenPrice,
                setTokenPrice,
            }}
        >
            {children}
        </ProSwapContext.Provider>
    )
}

export default ProSwapContext

export { ProSwapContextProvider as ProSwapContextProvider }
