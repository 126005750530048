import LogoIcon from "../icons/logo";
import Container from "./container";
import Button from "../button";
import React, { useState, useLayoutEffect, useContext, useEffect } from "react";
import { Link, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { useAddress, useConnectModal } from "hooks";
import WalletButton from "../walletButton";
import HeaderBackground from "./headerBackground";
import HeaderLink from "./headerLink";
import CloseIcon from "../icons/close";
import SwapChartContext from "../context/swapChart";
import WalletDropdown from "../walletDropdown";
import Navbar from 'components/platform/layout/Navbar'
import NFTnavbar from "../NFT/NFTnavbar";
import useResizeObserver from "use-resize-observer";


/*
  {
    text: "Vesting",
    href: "/vesting"
  },

 */

const navItems = [
  // {
  //   text: "Trade",
  //   href: "/trade",
  //   // dropdownLinks: [
  //   //   {
  //   //     text: "Swap",
  //   //     href: "/trade#Swap"
  //   //   },
  //   //   {
  //   //     text: "Liquidity",
  //   //     href: "/trade#Liquidity"
  //   //   },
  //   //   // {
  //   //   //   text: "Multiswap",
  //   //   //   href: "/trade#Multiswap"
  //   //   // },
  //   // ]
  // },
  // {
  //   text: "Earn",
  //   href: "/earn",
  //   dropdownLinks: [
  //     {
  //       text: "Pools",
  //       href: "/earn#Pools"
  //     },
  //     {
  //       text: "Farms",
  //       href: "/earn#Farms"
  //     },
  //     {
  //       text: "Liquidity",
  //       href: "/trade#Liquidity"
  //     },
  //   ]
  // },
  // {
  //   text: "Launch",
  //   href: "/launchpad"
  // },
  // {
  //   text: "Analytics",
  //   href: "/analytics",
  // },
  // {
  //   text: "NFT",
  //   href: "/nft",
  // },
  // {
  //   text: "IDO",
  //   href: "/idos",
  // },
]

const SwapTabList = [
  {
    label: "Swap",
    href: "/trade",
    regex: /\/trade((\/swap)|$|\/$)/
  },
  {
    label: "Pro Swap",
    href: "/trade/protrade",
    regex: /\/trade\/protrade/
  },
]

const AnalyticsTabList = [
  {
    label: "Overview",
    href: "/analytics",
    regex: /\/analytics((\/overview)|$|\/$)/
  },
  {
    label: "Pairs",
    href: "/analytics/pairs",
    regex: /\/analytics\/pairs/
  },
  {
    label: "Tokens",
    href: "/analytics/tokens",
    regex: /\/analytics\/tokens/
  },
  {
    label: "Transactions",
    href: "/analytics/transactions",
    regex: /\/analytics\/transactions/
  }
]

const EarnTabList = [
  {
    label: "Farms",
    href: "/earn#Farms",
    regex: /\/earn#Farms/
  },
  {
    label: "Pools",
    href: "/earn#Pools",
    regex: /\/earn#Pools/
  },
  {
    label: "Liquidity",
    href: "/trade#Liquidity",
    regex: /\/trade#Liquidity/
  }
]

const NFTTabList = [
  {
    label: "Collections",
    href: "/",
    regex: /\/nft$/

  },
  {
    label: "Activity",
    href: "/nft/activity",
    regex: /\/nft\/activity/

  },
]

const Header = () => {
  const { isOpen } = useContext(SwapChartContext)
  const connectModal = useConnectModal()
  const address = useAddress()
  const handleClick = () => connectModal.open()
  let { path } = useRouteMatch();
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const { ref: headerWithScrollObserver, height: headerWithScrollObserverHeight = 1 } = useResizeObserver<HTMLDivElement>();


  useLayoutEffect(() => {
    let phishingStatus = localStorage.getItem('phishingStatus') === 'true';
    if (!phishingStatus) {
      setOpen(true);
      localStorage.setItem('phishingStatus', `${false}`);
    }
  }, [])

  const onClick = () => {
    localStorage.setItem('phishingStatus', `${true}`);
    setOpen(false
    );
  }

  useEffect(() => {
    document.body.style.setProperty('--header-with-scroll-height', `${headerWithScrollObserverHeight}px`)
  }, [headerWithScrollObserverHeight])


  return (
    <div id="header" className={`z-full sticky top-0 isolate w-full right-0 left-0`} ref={headerWithScrollObserver}>
      <div className={`w-full bg-card  ${open ? "shadow-popup" : "h-0-px"} transition-all duration-300 overflow-hidden`}>
        <Container >
          <div className="col-span-full grid grid-cols-fr-max items-center">
            <div className="lg:text-center text-sm lg:text-base py-3">
              PHISHING WARNING: Make sure you're visiting <a href="https://nft.tfm.com/" className="font-bold underline hover: transition-all">https://nft.tfm.com</a> — check the URL carefully
            </div>
            <div className="flex" onClick={onClick}>
              <CloseIcon />
            </div>
          </div>
        </Container>
      </div>
      <div className="w-full flex justify-between py-2  px-3 md:px-12">
        <div className="flex">
          <div className="flex items-center">
            <div className="flex items-center pr-6">
              <div className="flex items-center mr-1">
                <LogoIcon width="32" height="32" />
              </div>
              <div className="text-lg font-centauri sm:block">
                TFM
              </div>
            </div>
          </div>

          <div className="hidden lg:flex items-center mx-auto justify-center w-full z-full">
            {navItems.map(item =>
              <div className="h-full z-20" key={item.text}>
                <HeaderLink text={item.text} href={item.href}
                  dropdownLinks={null}
                />
              </div>
            )}
          </div>

        </div>
        <div className="flex items-center z-5">
          {!address ?
            <Button paddingOff className="block px-4 py-2 text-sm" onClick={handleClick}>
              Connect Wallet
            </Button>
            :
            <WalletDropdown />
          }
        </div>
      </div>
      <div className="-z-1 isolate">
        <Switch>
          <Route path={`/trade`}>
            {
              location.hash === 'Liquidity' ?
                <Navbar tabList={EarnTabList} gridStyle="proSwap-container" liquidity />
                :
                <Navbar tabList={SwapTabList} gridStyle="proSwap-container" withSearch />
            }
          </Route>
          <Route path={`/analytics`}>
            <Navbar tabList={AnalyticsTabList} gridStyle="analytics-container" withSearch />
          </Route>
          <Route path={`/earn`}>
            <Navbar tabList={EarnTabList} />
          </Route>
          <Route path={`/nft`}>
            <NFTnavbar tabList={NFTTabList} withSearch />
          </Route>
        </Switch>
      </div>
      <HeaderBackground />
    </div>
  );
}

export default Header
