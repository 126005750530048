import React, { useContext, useEffect, useRef, useState } from "react"
import SwitchRowItem from '../tableSwitch'
import SwitchRow from '../switchRow'
import MagnifyingGlassIcon from '../icons/magnifyingGlass'
import { getTokenIcon } from '../../../helpers/token'
import useDebounce from '../../landing/hooks/useDebounce'
import CloseIcon from '../icons/close'
import axios from '../../../rest/request'
import TokenContext from '../../../layouts/TokenContext'
import ProSwapContext from '../context/proSwap'
import NftContext from "../context/nft"
import SearchBarDropDownList from "./parts/SearchBarDropdownList"
import LoaderIcon from "../icons/loader"

const tabs = [
    { label: "Top Volume" },
    { label: "Gainers" },
    { label: "Losers" },
]

const NFTsearch = () => {
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [openSearch, setOpenSearch] = useState(false)
    const [debounceSearch] = useDebounce(searchValue, 300)
    const [inputMouseEnter, setInputMouseEnter] = useState(false)
    const searchMenu = useRef(null)
    const { makeSearch } = useContext(NftContext)
    const [tokens, setTokens] = useState([])
    const [collections, setCollections] = useState([])


    const handleClick = (e) => {
        if (!searchMenu?.current?.contains(e?.target)) {
            setOpenSearch(false)
        }
    }

    // close search by clicking on a third-party element
    useEffect(() => {
        if (openSearch) {
            document.addEventListener("click", handleClick)
            return () => {
                document.removeEventListener("click", handleClick)
            }
        }
    }, [openSearch])

    const getSearchResponse = async () => {
        try {
            const response = await makeSearch(searchValue)
            if (response) {
                setTokens(response.tokens)
                setCollections(response.collections)
            }
        } catch (e) {
            console.warn(e.message)
        }
    }

    useEffect(() => {
        getSearchResponse()
    }, [debounceSearch])

    return (
        <div ref={searchMenu} className="relative w-full max-w-full">
            <div
                onClick={() => {
                    setOpenSearch(true)
                }}
                onMouseEnter={() => {
                    setInputMouseEnter(true)
                }}
                onMouseLeave={() => {
                    setInputMouseEnter(false)
                }}
                className={`flex items-center pl-3 pr-4 lg: pl-4 py-2 searchBorder rounded-base cursor-pointer hover:border-search h-10 relative w-full 
				${openSearch ? "searchInputActive noBottomBorder" : 'justify-center'} `}
            >
                <div className="hidden xl:flex items-center">
                    <MagnifyingGlassIcon strokeColor={inputMouseEnter ? '#FFFFFF' : '#45434F'} />
                </div>

                <div className={`xl:hidden flex items-center justify-center ${openSearch && "right-1px relative"} `}>
                    <MagnifyingGlassIcon width="20" height="20" strokeColor="#FFFFFF" />
                </div>

                <input
                    style={{ textOverflow: 'ellipsis' }}
                    className={`ml-2 w-full text-base placeholder-black-60 font-poppins`}
                    id="search"
                    name="search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    autoComplete="off"
                    placeholder="Search items and collections"
                />

                <div
                    className={`${openSearch ? "flex items-center  xl:hidden" : 'hidden'} `}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setOpenSearch(false)
                        setSearchValue("")
                    }}
                >
                    <CloseIcon width="20" height="20" />
                </div>


            </div>
            {openSearch && (
                <div className="absolute top-39 left-0 w-full z-10">
                    <div
                        className={`w-full favorites rounded-lg text-white font-poppins overflow-hidden ${openSearch ? "noTopBorder searchOpenBorder" : ""}`}
                    >
                        {
                            (collections.length > 0 || tokens.length > 0) ?
                                <div className={`transition-all`}>
                                    <SearchBarDropDownList collections={collections} tokens={tokens} close = {()=>setOpenSearch(false)}/>
                                </div>
                                :
                                <div className="flex justify-center items-center py-4">
                                    <div>Loading</div>

                                    <div className="ml-ch h-8">
                                        <LoaderIcon />
                                    </div>
                                </div>
                        }


                    </div>
                </div>
            )}
        </div>

    )
}

export default NFTsearch
