import React from "react";
import { Link, Switch } from "react-router-dom";
import formatNumbers from "helpers/formatNumbers";
import tokensDictionary from 'constants/tokensDictionary.json';
import marketsDictionary from 'constants/marketsDictionary';
import ImageChecker from "./imageChecker";

const getPriceTitle = (status) => {
    let a = ''
    if (status === 'sell')
        a = 'Buy for'

    if (status === 'auction')
        a = 'Current bid'

    return a
}

const SearchTokenItem = ({ item, close }) => {
    return (
        <Link to={`/nft/collections/token/${item.collectionAddr}/${item.tokenId}`}
        onClick={close} >
            <div className="py-2 lg:text-base  hover:bg-black-70 flex items-center px-4">
                <div className="flex items-center mr-2">
                <ImageChecker 
                    url={item.imageUrl} 
                    containerClassName = {'rounded-full w-8 h-8 mr-2 text-sm'} 
                    noPlayButton 
                    className="w-full"
                    loaderHeight="h-4"
                    />                </div>
                <div className="">
                    <div className="text-sm">{item.name}</div>
                    <div className='opacity-70 text-xs lg:text-xs-d  flex items-center '>
                        Rank: {item.rank} •
                        {item?.price && item?.denom && item?.status ?
                            <>
                                <img src={marketsDictionary[item?.marketListing]?.image} className='w-3 h-3 lg:w-4 lg:h-4 mx-1' />
                                {getPriceTitle(item.status)}
                                <img src={tokensDictionary[item?.denom]?.icon} className='w-3 h-3 lg:w-4 lg:h-4 mx-1' />
                                {item.price / 10 ** tokensDictionary[item?.denom]?.decimals}
                            </>
                            :
                            <span className="ml-1">
                                Not Listed
                            </span>
                        }
                    </div>
                </div>
            </div>
        </Link>

    )
}
export default SearchTokenItem