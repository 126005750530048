import BigNumber from "bignumber.js"
import { GetNumberLength, getNumberSymbol } from "helpers/chart"

const formatNumbers = (value) => {
  const correctLength = (value) => value - (value % 3) - (value % 3 === 0 ? 3 : 0)
  const length = correctLength(GetNumberLength(value))
  const decimals = 2
  const divider = new BigNumber(Math.pow(10, length)).decimalPlaces(decimals)
  const symbol = getNumberSymbol(correctLength(GetNumberLength(value)))
  const formattedValue = new BigNumber(value).div(divider).decimalPlaces(decimals).toNumber()
  return isFinite(formattedValue) ? `${formattedValue}${symbol}` : 0
}

export const getVisableDecimal = (value, defaultDecimals = 3) => {
  const valueBN = new BigNumber(value ?? 0)
  if (valueBN.eq(0)) return 0
  if (valueBN.gte(1)) return defaultDecimals

  const decimalas = valueBN.minus(valueBN.toFixed(0, 1)).toString().replace("0.", "")

  const e = decimalas.match(/e-(\d+)/)?.[1] ?? null
  if (e) {
    return parseInt(e)
  } else {
    const firstNotZero = decimalas.match(/0+\d/)?.[0]

    return firstNotZero?.length > defaultDecimals ? firstNotZero?.length : defaultDecimals
  }
}

export const valueOrNull = (value) => value ? value : null


export default formatNumbers
