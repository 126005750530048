import { MouseEventHandler } from "react";
import React from "react";

export interface CustomButtonProps extends ButtonAttrs {
  onClick?: MouseEventHandler<HTMLButtonElement>,
  children: any,
  className?: string,
  disabled?: boolean,
  type?: "button" | "submit" | "reset" | undefined,
  id?: any | undefined
  accent?: boolean
  bgOff?: boolean
  paddingOff?: boolean
  roundedOff?: boolean
}

const Button = ({ onClick = () => { }, id, children, className = "", disabled = false, type = "button", accent = false, bgOff = false, paddingOff = false, roundedOff = false }: CustomButtonProps) => {
  return (
    <button {...{ onClick, disabled, type, id }} className={`${paddingOff ? "" : "px-4 py-3"} ${roundedOff ? "" : "rounded-md"} h-min  shadow-button text-theme-black ${disabled ? "bg-action bg-opacity-30" : "bg-action hover:bg-action-hover"} ${className}`}>
      {children}
    </button>
  );
}

export default Button
