export const subServiceToQuery = (
    subService: string,
    collectionAddr: string,
    // limit: number,
    resolutionSec: number,
    timeFrom: number,
    timeTo: number
) => {
    if (subService === "floorPriceDistribution") {
        return `query MyQuery {
            nftStats(collectionAddr: "${collectionAddr}", timeFrom: ${timeFrom}, timeTo: ${timeTo}, resolutionSec: ${resolutionSec}) {
                collectionAddr
                floorPriceDistribution {
                    map
                    median
                }
            }
        }`
    } else if (subService === "floor_price") {
        return `query MyQuery {
            nftStats(collectionAddr: "${collectionAddr}", queryType:"${subService}", resolution: ${resolutionSec}) {
                content{
                    timestamp
                    value
                }
            }
        }`
    } else if (subService === "holdersCount") {
        return `query MyQuery {
            nftStats(collectionAddr: "${collectionAddr}", timeFrom: ${timeFrom}, timeTo: ${timeTo}, resolutionSec: ${resolutionSec}) {
                collectionAddr
                holdersCount
            }
        }`
    } else if (subService === "holdersCountHistory") {
        return `query MyQuery {
            nftStats(collectionAddr: "${collectionAddr}", timeFrom: ${timeFrom}, timeTo: ${timeTo}, resolutionSec: ${resolutionSec}) {
                collectionAddr
                holdersCountHistory {
                    datetime
                    holdersCount
                    holdersMap
                    timestamp
                }
            }
        }`
    } else if (subService === "holdersDistribution") {
        return `query MyQuery {
            nftStats(collectionAddr: "${collectionAddr}", timeFrom: ${timeFrom}, timeTo: ${timeTo}, resolutionSec: ${resolutionSec}) {
                collectionAddr
                holdersDistribution {
                    map
                    median
                }
            }
        }`
    } else if (subService === "listedCount") {
        return `query MyQuery {
            nftStats(collectionAddr: "${collectionAddr}", timeFrom: ${timeFrom}, timeTo: ${timeTo}, resolutionSec: ${resolutionSec}) {
                collectionAddr
                listedCount {
                    listings
                    datetime
                    timestamp
                }
            }
        }`
    } else if (subService === "volumeChange") {
        return `query MyQuery {
            nftStats(collectionAddr: "${collectionAddr}", timeFrom: ${timeFrom}, timeTo: ${timeTo}, resolutionSec: ${resolutionSec}) {
                collectionAddr
                volumeChange {
                    dataset {
                        datetime
                        timestamp
                        volume
                    }
                    maxVolume
                    totalVolume
                }
            }
        }`
    }
}